import {Component} from "@angular/core";
import {ActivationEnd, Router} from "@angular/router";

@Component({
  selector: 'dvs-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent {
  centered = true;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      // 2. Start with ActivationEnd, experiment with the other events
      if (event instanceof ActivationEnd) {
        // 3. if your data exists assign it for use!
        if (event.snapshot.data.hasOwnProperty('centered')) {
          this.centered = event.snapshot.data['centered'];
        }
      }
    });

  }
}
