import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class CookieInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // send cookies only to the same origin
    if (req.url.startsWith("/")) {
      req = req.clone({
        withCredentials: true
      });
    }

    return next.handle(req);
  }

}
