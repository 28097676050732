import {Component} from "@angular/core";
import pi from '../../../../../package.json';

@Component({
  selector: 'dvs-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  version: string = pi.version;
  logo: any = {
    src: "/public/assets/images/logo-small.svg",
    alt: "DVS CONNECT",
    width: "97"
  };
}
