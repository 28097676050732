import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolQueryDto} from "@app/shared/dto/tool/tool.query.dto";
import {ToolDto} from "@app/shared/dto/tool/tool.dto";
import {ToolDetailsDto} from "@app/shared/dto/tool/tool-details.dto";
import {ToolMountReportDto} from "@app/shared/dto/tool/tool-mount-report.dto";
import {ToolUnmountReportDto} from "@app/shared/dto/tool/tool-unmount-report.dto";
import {ToolStatusUpdateDto} from "@app/shared/dto/tool/tool-status-update.dto";
import {ToolUsageDataDto} from "@app/shared/dto/tool/tool-usage-data.dto";
import {ToolUsageCorrectionRequestDto} from "@app/shared/dto/tool/tool-usage-correction-request.dto";
import {Observable} from "rxjs";
import {CreateToolDto} from "@app/shared/dto/tool/create.tool.dto";
import {ToolMountUnmountReportDto} from "@app/shared/dto/tool/tool-mount-unmount-report.dto";
import {ToolSimulationRequestDto} from "@app/shared/dto/tool/tool-simulation-reqeust.dto";
import {ToolSimulationResponseDto} from "@app/shared/dto/tool/tool-simulation-response.dto";
import {ToolMeasurementDataDto} from "@app/shared/dto/tool/tool-measurement-data.dto";
import {ToolUpdateDto} from "@app/shared/dto/tool/tool.update.dto";
import {ToolReportFlagDto} from "@app/shared/dto/tool/tool-report-flag.dto";

@Injectable()
export class ToolService {
  private readonly baseUrl: string;

  private readonly baseUrlKPIs: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tools';
    this.baseUrlKPIs = '/tools-kpi-api/api/v1';
  }

  findAll(query: ToolQueryDto): Observable<ToolDto[]> {
    return this.http.post<ToolDto[]>(this.baseUrl, query);
  }

  update(id: string, update: ToolUpdateDto, fields: string[] = []): Observable<ToolDto> {
      const params = {};
      if (fields.length > 0) {
        params['fields'] = fields;
      }
      return this.http.post<ToolDto>(`${this.baseUrl}/${id}`, update, {params});
  }

  findToolDetails(id: string): Observable<ToolDetailsDto> {
    return this.http.get<ToolDetailsDto>(`${this.baseUrl}/${id}`);
  }

  updateStatus(toolId: string, statusUpdate: ToolStatusUpdateDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/tool-status`, statusUpdate);
  }

  addToolMeasurementData(toolId: string, report: ToolMeasurementDataDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/measurements`, report);
  }

  reportToolMount(toolId: string, report: ToolMountReportDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/mount`, report);
  }

  reportToolUnmount(toolId: string, report: ToolUnmountReportDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/unmount`, report);
  }

  reportToolMountUnmount(toolId: string, report: ToolMountUnmountReportDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/mount-unmount`, report);
  }

  findToolUsageData(toolId: string): Observable<ToolUsageDataDto[]> {
    return this.http.get<ToolUsageDataDto[]>(`${this.baseUrl}/${toolId}/usage-data`);
  }

  correctToolUsage(toolId: string, request: ToolUsageCorrectionRequestDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/usage-data/correction`, request);
  }

  archiveTool(toolId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${toolId}`);
  }

  unarchiveTool(toolId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/unarchive`, null);
  }

  addTool(request: CreateToolDto): Observable<void>  {
    return this.http.post<void>(`${this.baseUrl}/new`, request);
  }

  flagTool(toolId: string, request: ToolReportFlagDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/flag`, request);
  }

  unflagTool(toolId: string): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/${toolId}/unflag`);
  }

  getOrderScheduleByConfig(toolProjectId: string): Observable<ToolSimulationResponseDto>  {
    let observationDate = new Date().toISOString().slice(0, 10);
    let observationDays = 30;
    let requestBody = new ToolSimulationRequestDto();
    requestBody.target_cycles_per_tool = 14;
    requestBody.target_usages_per_cycle = 1100;
    requestBody.weekly_target_usages = 30000;
    requestBody.production_days.set("monday", true);
    requestBody.production_days.set("tuesday", true);
    requestBody.production_days.set("wednesday", true);
    requestBody.production_days.set("thursday", true);
    requestBody.production_days.set("friday", true);
    requestBody.production_days.set("saturday", false);
    requestBody.production_days.set("sunday", false);
    requestBody.sim_times.set("in_order", 240);
    requestBody.sim_times.set("in_creation", 60480);
    requestBody.sim_times.set("in_delivery", 4320);
    requestBody.sim_times.set("ready", 0);
    requestBody.sim_times.set("in_use", 60);
    requestBody.sim_times.set("unusable", 2880);
    requestBody.sim_times.set("in_return", 2880);
    requestBody.sim_times.set("waiting_for_analysis", 240);
    requestBody.sim_times.set("in_analysis", 240);
    requestBody.sim_times.set("renewable", 60);
    requestBody.sim_times.set("in_renewal", 30240);
    requestBody.simulation_weeks = 52;
    requestBody.active_inventory_thres = 20;
    requestBody.allow_order = true;
    requestBody.allow_historical_orders = false;
    requestBody.return_simulation_log = true;
    // let url = `http://localhost:5000/public/assets/static-files/orderSchedule.json`;
    // return this.http.get<ToolSimulationResponseDto>(url);
    let url = `${this.baseUrlKPIs}/demand/simulation/${toolProjectId}/weekly-target-simulation`;
    return this.http.post<ToolSimulationResponseDto>(url, requestBody);
  }
}
