/**
 * additional parameters for the chart
 * periodName is a mandatory property
 * is no other properties are set, than the date will formatted as "DD-MM-YYYY"
 *
 * Attention: if showDateAsPeriod = true, then periodFormat MUST bes set as well
 *
 * Examples of correct set properties:
 *
 * * Week-number: {
 *  periodName: ChartDataPeriod.WEEK,
 *  showDateAsPeriod: true,
 *  periodFormat: ChartDataPeriodFormat.WEEK_NUMBER
 *  }
 *
 *   * Month-number: {
 *  periodName: ChartDataPeriod.MONTH,
 *  showDateAsPeriod: true,
 *  periodFormat: ChartDataPeriodFormat.MONTH_NUMBER
 *  }
 *
 *   *  Month-Name: {
 *  periodName: ChartDataPeriod.MONTH,
 *  showDateAsPeriod: true,
 *  periodFormat: ChartDataPeriodFormat.MONTH_NAME
 *  }
 */
export interface ProductionChartParameters {
  dateFormat?: string;
  periodName: ChartDataPeriod;
  showDateAsPeriod?: boolean;
  periodFormat?: ChartDataPeriodFormat;
}

export enum ChartDataPeriod {
  DAY = "day",
  WEEK = "week",
  MONTH = "month"
}

export enum ChartDataPeriodFormat {
  WEEK_NUMBER = "WEEK_NUMBER",
  MONTH_NUMBER = "MONTH_NUMBER",
  MONTH_NAME = "MONTH_NAME",
  MONTH_NAME_PLUS_YEAR = "MONTH_NAME_PLUS_YEAR"
}

export class ChartDataDefaults implements ProductionChartParameters{
  dateFormat = "DD-MM-YYYY";
  periodName = ChartDataPeriod.DAY;
  showDateAsPeriod: false;
  showDateAsPeriodNumber = ChartDataPeriodFormat.WEEK_NUMBER;
}
