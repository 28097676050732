import {ToastType} from "@app/shared/model/toast/toast-type";

export class Toast {
  id?: string
  header: string
  description: string
  timeout?: number = 5000;
  type: ToastType
  headerParams?: object;
  descriptionParams?: object;

  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}
