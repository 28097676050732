import {BaseQueryDto} from "@app/shared/dto/common/base.query.dto";

export class ToolQueryDto extends BaseQueryDto{
  dmc: string;
  toolProjectId?: string;
  toolStatusId?: string;
  excludeToolStatusId?: string;
  archived = "false";

  constructor() {
    super();
    this.queryType = 'tool';
  }
}
