import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'file_size'
})
export class FileSizePipe implements PipeTransform {
  constructor() { }

  transform(value: number, fractionDigits = 2): string {
    if (value) {
      const sufixes = ['B', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(value) / Math.log(1024));
      return (!value && '0 B') || (value / Math.pow(1024, i)).toFixed(fractionDigits) + ' ' + sufixes[i];
    } else {
      return "";
    }
  }
}
