import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolStatusQueryDto} from "@app/shared/dto/tool/tool-status.query.dto";
import {ToolStatusDto} from "@app/shared/dto/tool/tool-status.dto";
import {Observable} from "rxjs";

@Injectable()
export class ToolStatusService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tool-statuses';
  }

  findAll(query: ToolStatusQueryDto): Observable<ToolStatusDto[]> {
    return this.http.post<ToolStatusDto[]>(this.baseUrl, query);
  }

}
