import {NgModule} from "@angular/core";
import {AutofocusDirective} from "@app/shared/directives/autofocus.directive";

const directives = [
  AutofocusDirective
];

@NgModule({
  declarations: directives,
  exports: [
    ...directives
  ]
})
export class SharedDirectivesModule {

}
