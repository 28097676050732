import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject, Subscription} from "rxjs";
import {ScannerService} from "@app/shared/services/scanner.service";
import {map, take} from "rxjs/operators";
import {ToolQueryDto} from "@app/shared/dto/tool/tool.query.dto";
import {ToolService} from "@app/shared/services/tool.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ActionCode} from "@app/shared/model/action/action-code";
import {ActionCodeQueryDto} from "@app/shared/dto/action/action-code-query-dto";
import {ActionCodeParameter} from "@app/shared/model/action/action-code-parameter";
import {ToolDto} from "@app/shared/dto/tool/tool.dto";

@Injectable({
  providedIn: 'root'
})
export class ActionService implements OnDestroy {
  private baseUrl = '/api/actions-codes'

  private subject: Subject<ActionCode> = new Subject<ActionCode>()

  private scannerSubscription: Subscription

  allActionsUnfiltered$: Observable<ActionCode[]>;

  // actions: ActionCode[];

  constructor(private http: HttpClient,
              private scannerService: ScannerService,
              private router: Router,
              private toolService: ToolService) {

    const actionCodeQuery = new ActionCodeQueryDto();
    actionCodeQuery.pageSize = 99999;
    this.allActionsUnfiltered$ = this.http.post<ActionCode[]>(this.baseUrl, actionCodeQuery).pipe(take(1));

    this.scannerSubscription = this.scannerService.subscribe((code: string) => this.onScannerEvent(code))
  }

  ngOnDestroy(): void {
    this.scannerSubscription.unsubscribe()
    this.subject.complete()
  }


  onScannerEvent(code: string): void {
    // console.log("ActionService. onScannerEvent, Code:", code);
    this.allActionsUnfiltered$
      .pipe(
        take(1),
        map((actionCodes: ActionCode[]) => {
          return actionCodes.filter((action) => action.code === code)
        })
      )
      .subscribe((actionCodesFiltered: ActionCode[]): void => {
        if (actionCodesFiltered.length > 0) {
          this.subject.next(actionCodesFiltered[0])
          return
        }

        const toolQuery = new ToolQueryDto();
        toolQuery.dmc   = code;


        // works on every page if DMC code is known go to detail page
        this.toolService.findAll(toolQuery)
          .pipe(
            take(1)
          ).subscribe((response: ToolDto[]) => {
            console.log("found toolDtos:", response)
            if (response && response.length > 0) {
              this.router.navigate(['/tools', response[0].id])
            }
          })
      })
  }


  getActionSubscription(): Subject<ActionCode> {
    return this.subject
  }

  /**
   * return filtered action codes
   * @param query object with properties, which should be checked to pass a filtering
   * For example if actionQueryDto is empty no filtering would be done
   */
  findAll(query: ActionCodeQueryDto): Observable<ActionCode[]> {
    // console.log("ActionService.findAll ...", query)
    return this.http.post<ActionCode[]>(this.baseUrl, query)
  }

  addAction(action: ActionCode): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/add`, action)
  }

  deleteAction(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`)
  }

  /**
   * return actionCodeParameter from Array, if not found returns undefined or null
   * @param actionParameters array of ActionCodeParameters
   * @param paramName name of the parameter
   * Example:  actionParameters = [ {"name": "machine",  "value": "M002"},   {"name": "spindle",  "value": "E1"} ]
   * paramName: "machine"
   * returning: {"name": "machine",  "value": "M002"}
   */
  getActionCodeParameterByName(actionParameters: ActionCodeParameter[], paramName: string): ActionCodeParameter | undefined | null {
    if (!actionParameters || actionParameters.length === 0) {
      return null;
    }
    return actionParameters.find((elem) => elem.name === paramName);
  }

}
