import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineContingentQueryDto} from "@app/shared/dto/machine-contingent/machine-contingent.query.dto";
import {MachineContingentDto} from "@app/shared/dto/machine-contingent/machine-contingent.dto";
import {Observable} from "rxjs";

@Injectable()
export class MachineContingentService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/machine-contingents';
  }

  findAll(query: MachineContingentQueryDto): Observable<MachineContingentDto[]> {
    return this.http.post<MachineContingentDto[]>(this.baseUrl, query);
  }

  countAll(query: MachineContingentQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

}
