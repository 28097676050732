import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
  name: 'fraction_digits'
})
export class FractionDigitsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(text: string, currency: string): SafeHtml {
    if (text) {
      return this.sanitizer.bypassSecurityTrustHtml(
        text.substr(0, text.length - 2) + "<span class='fraction-gray'>" + text.substr(text.length-2, text.length) + "</span> " + currency
      );
    } else {
      return "0.00 " + currency
    }
  }
}
