<input *ngIf="!visible" type="file" style="display: none;" (change)="onFileSelected($event)" #fileUploadInputField>
<dvs-filepicker
  [ngClass]="visible ? '' : 'hidden'"
  *ngIf="visible"
  [drop]="drop"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [accept]="accept"
  (changed)="onFileSelected($event)">
</dvs-filepicker>
<div *ngFor="let file of files">
  <div class="upload-container" *ngIf="file.showProgress">
    <dvs-single-bar-chart
      style="--bar-size: 12px"
      [value]="file.percentDone"
      [labelPosition]="'end'"
      [direction]="'right-to-left'">
    </dvs-single-bar-chart>
    <div class="progress_bar_file_name">{{ file.name }}</div>
  </div>
</div>

