import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CustomerUserQueryDto} from "@app/shared/dto/user/customer-user.query.dto";
import {CustomerUserDto} from "@app/shared/dto/user/customer-user.dto";

@Injectable()
export class CustomerUserService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/customer-users';
  }

  findAll(query: CustomerUserQueryDto): Observable<CustomerUserDto[]> {
    return this.http.post<CustomerUserDto[]>(this.baseUrl, query);
  }

  countAll(query: CustomerUserQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

}