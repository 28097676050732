import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {InvitationQueryDto} from "@app/shared/dto/invitation/invitation.query.dto";
import {InvitationDto} from "@app/shared/dto/invitation/invitation.dto";
import {CreateInvitationDto} from "@app/shared/dto/invitation/create.invitation.dto";
import {InvitationConfirmationQueryDto} from "@app/shared/dto/invitation/invitation-confirmation.query.dto";
import {InvitationConfirmationUpdateDto} from "@app/shared/dto/invitation/invitation-confirmation.update.dto";

@Injectable()
export class InvitationService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/invitations';
  }

  findAll(query: InvitationQueryDto): Observable<InvitationDto[]> {
    return this.http.post<InvitationDto[]>(this.baseUrl, query);
  }

  countAll(query: InvitationQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

  createInvitation(request: CreateInvitationDto): Observable<InvitationDto>  {
    return this.http.put<InvitationDto>(`${this.baseUrl}`, request);
  }

  readInvitation(query: InvitationConfirmationQueryDto): Observable<InvitationDto>  {
    return this.http.post<InvitationDto>(`/invitation-confirmation`, query);
  }

  acceptDeclineInvitation(query: InvitationConfirmationUpdateDto): Observable<InvitationDto>  {
    return this.http.post<InvitationDto>(`/invitation-confirmation/accept-decline`, query);
  }

  cancelInvitation(id: string) {
    return this.http.post<void>(`${this.baseUrl}/${id}/cancel`, undefined);
  }
}