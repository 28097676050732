import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BaseLayoutComponent} from '@app/shared/components/layouts/base-layout/base-layout.component';

// @ts-ignore
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: BaseLayoutComponent, children: [
      { path: 'home', loadChildren: () => import('@app/views/home/home.module').then(m => m.HomeModule)},
      { path: 'productions', loadChildren: () => import('@app/views/productions/productions.module').then(m => m.ProductionsModule) },
      { path: 'tools', loadChildren: () => import('@app/views/tool/tool.module').then(m => m.ToolModule) },
      { path: '', loadChildren: () => import('@app/views/session/session.module').then(m => m.SessionModule) },
      { path: 'machines', loadChildren: () => import('@app/views/machine/machine.module').then(m => m.MachineModule)},
      { path: 'products', loadChildren: () => import('@app/views/product/product.module').then(m => m.ProductModule)},
      { path: 'tool-projects', loadChildren: () => import('@app/views/tool-project/tool-project.module').then(m => m.ToolProjectModule)},
      { path: 'access-keys', loadChildren: () => import('@app/views/access-key/access-key.module').then(m => m.AccessKeyModule) },
      { path: 'user-management', loadChildren: () => import('@app/views/user-management/user-management.module').then(m => m.UserManagementModule) },
      { path: 'software-download', loadChildren: () => import ('@app/views/software-downloads/software-download.module').then(m => m.SoftwareDownloadModule) },
      { path: 'user-management', loadChildren: () => import('@app/views/user-management/user-management.module').then(m => m.UserManagementModule) },
      { path: 'ext', loadChildren: () => import('@app/views/external-app/external-app.module').then(m => m.ExternalAppModule) },
      { path: 'personal-settings', loadChildren: () => import('@app/views/personal-settings/personal-settings.module').then(m => m.PersonalSettingsModule) },
      { path: 'support', loadChildren: () => import('@app/views/support/support.module').then(m => m.SupportModule) },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "top", onSameUrlNavigation: "reload"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
