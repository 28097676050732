import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Alert} from "@app/shared/model/alert/alert";
import {filter} from "rxjs/operators";
import {AlertOptions} from "@app/shared/model/alert/alert-options";
import {AlertType} from "@app/shared/model/alert/alert-type";

@Injectable({providedIn: 'root'})
export class AlertService {
  private subject = new Subject<Alert>()
  private defaultId = 'default-alert';

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success(message: string, options?: AlertOptions): void {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  error(message: string, options?: AlertOptions): void {
    this.alert(new Alert({ ...options, type: AlertType.Danger, message }));
  }

  info(message: string, options?: AlertOptions): void {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  // main alert method
  alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId): void {
    this.subject.next(new Alert({ id }));
  }
}
