import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {ERROR_MESSAGE} from "@app/shared/error/error-message";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'formError',
  pure: false
})
export class FormErrorPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(control: FormControl | AbstractControl): string | null {
    if (!control || control.pristine || !control.errors) {
      return null;
    }

    const errors = Object.entries(control.errors || {});

    let [errorKey, errorValue] = errors[0];
    const errorMessageKey = ERROR_MESSAGE[errorKey];

    if (!errorMessageKey) {
      return '';
    }

    if (errorValue) {
      errorValue = { [Object.keys(errorValue)[0]]: Object.values(errorValue)[0]};
    }
    return this.translateService.instant(errorMessageKey, errorValue);
  }

}
