import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {Toast} from "@app/shared/model/toast/toast";
import {ToastType} from "@app/shared/model/toast/toast-type";
import {ToastOptions} from "@app/shared/model/toast/toast-options";

@Injectable({providedIn: "root"})
export class ToastService {
  private subject = new Subject<Toast>()
  private defaultId = 'default-alert';

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Toast> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success(header: string, description: string, options?: ToastOptions): void {
    this.toast(new Toast({ header, description, type: ToastType.Success, ...options }));
  }

  error(header: string, description: string, options?: ToastOptions): void {
    this.toast(new Toast({ header, description, type: ToastType.Danger, ...options }));
  }

  warning(header: string, description: string, options?: ToastOptions): void {
    this.toast(new Toast({ header, description, type: ToastType.Warning, ...options }))
  }

  info(header: string, description: string, options?: ToastOptions): void {
    this.toast(new Toast({ header, description, type: ToastType.Info, ...options }));
  }

  // main toast method
  toast(toast: Toast): void {
    toast.id = toast.id || this.defaultId;
    this.subject.next(toast);
  }

  // clear alerts
  clear(id = this.defaultId): void {
    this.subject.next(new Toast({ id }));
  }
}
