import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BaseLayoutComponent} from "@app/shared/components/layouts/base-layout/base-layout.component";
import {RouterModule} from "@angular/router";
import {ScannerComponent} from "@app/shared/components/scanner/scanner.component";
import {DmcScannerComponent} from "@app/shared/components/dmc-scanner/dmc-scanner.component";
import {MatDialogModule} from "@angular/material/dialog";
import {HeaderComponent} from "@app/shared/components/header/header.component";
import {FooterComponent} from "@app/shared/components/footer/footer.component";
import {TranslateModule} from "@ngx-translate/core";
import {OverlayComponent} from "@app/shared/components/overlay/overlay.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IMaskModule} from "angular-imask";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {UserSettingsComponent} from "@app/shared/components/user-settings/user-settings.component";
import {ApiDocumentationComponent} from "@app/shared/components/api-documentation/api-documentation.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FileUploadComponent} from "@app/shared/components/file-upload/file-upload.component";
import {ComponentsAngularModule} from "@dvs-design-system/components-angular";
import {CustomerApplicationService} from "@app/shared/services/customer/customer-application.service";
import {AlertComponent} from "@app/shared/components/alert/alert.component";
import {ModalFormComponent} from "@app/shared/components/modal-form/modal-form.component";
import {LoaderComponent} from "@app/shared/components/loader/loader.component";
import {ToastComponent} from "@app/shared/components/toast/toast.component";

const components = [
  BaseLayoutComponent,
  DmcScannerComponent,
  ScannerComponent,
  HeaderComponent,
  FooterComponent,
  OverlayComponent,
  UserSettingsComponent,
  ApiDocumentationComponent,
  FileUploadComponent,
  AlertComponent,
  ToastComponent,
  ModalFormComponent,
  LoaderComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IMaskModule,
    TranslateModule,
    ScrollingModule,
    MatProgressBarModule,
    ComponentsAngularModule
  ],
  providers: [
    CustomerApplicationService
  ],
  declarations: components,
  exports: [
    ...components,
    IMaskModule,
    ComponentsAngularModule
  ]
})
export class SharedComponentsModule {

}
