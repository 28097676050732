import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ProductSupport} from '@app/shared/model/production/product-support';
import {map} from "rxjs/operators";
import {Product} from "@app/shared/model/production/product";
import {ProductDetail} from "@app/shared/model/production/product-detail";
import {ProductList} from "@app/shared/model/production/product-list";
import {ProductionChartParameters} from "@app/shared/model/production/production-summary-chart-parameters";
import _ from "lodash";
import {DataGramAggregationDataDto, DataGroup} from "@app/shared/dto/production/datagram-aggregation-data.dto.dto";
import {ChartDataSeries} from "@app/shared/model/nvd3-chart/chart-data";
import {ProductionDataMapperService} from "@app/shared/mappers/production/production-data-mapper.service";

@Injectable()
/**
 * service handles all request for data portal (laufende Produktionen)
 */
export class ProductionService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, private mapperService: ProductionDataMapperService) {
    this.baseUrl = '/datagram-service/api/v2';
  }

  private static getSortedDataByTimestamp(data: DataGramAggregationDataDto): DataGramAggregationDataDto {
    const group: DataGroup = {
      group_definition: data?.groups[0]?.group_definition,
      results: _.orderBy(data?.groups[0]?.results, ["start_timestamp"])
    };
    return { aggregation_period: data.aggregation_period, groups: [group]};
  }

  getProductDetailById(projectId: string): ProductDetail {
    const productDto: Product = {id: projectId, name: projectId, number: "stat: Project A", type: "stat: Ring Gear"};
    return {
      ...productDto,
      design: 'stat: 234052',
      productSupport: new ProductSupport(),
      productionStart: "stat: 01.10.2021"
    };
  }

  getProducts(): Observable<Product[]> {
    return this.getProductList()
      .pipe(
        map((productList: ProductList) => {
          const products: Product[] = [];
          const productIds: string[] = productList.ids;
          productIds.forEach((id: string) => {
            products.push( {
              id,
              name: id,
              number: id,
              type: "Ring Gear"
            });
          });
          return products;
        })
      );
  }

  getDMCTableNumberOfRowsByProject(projectId: string): Observable<number> {
    let url: string = this.baseUrl;
    url += `/query/exact/aggregated?project_id=${projectId}`;
    url += `&timestamp_name=creation_timestamp`;
    url += `&group_by=project_id`;
    url += `&aggregation_period=year`;

    return this.http.get<DataGramAggregationDataDto>(url)
    .pipe(
      map((response: DataGramAggregationDataDto): number => this.mapperService.getRowsCountFromDto(response))
    );
  }

  /**
   * get data for the chartf
   * @param params any other parameters for the future
   * @param projectId if of the project
   */
  getChartData(projectId: string, params: ProductionChartParameters): Observable<ChartDataSeries[]> {
    let url: string = this.baseUrl;
    url += `/query/exact/aggregated?project_id=${projectId}`;
    url += `&timestamp_name=start_timestamp&aggregation_period=${params.periodName}`;

    return this.http.get<DataGramAggregationDataDto>(url)
      .pipe(
        map((data: DataGramAggregationDataDto) => {
          const sortedData: DataGramAggregationDataDto = ProductionService.getSortedDataByTimestamp(data);
          return this.mapperService.toChartDataFromDto(sortedData, params)
        })
      );
  }

  private getProductList(): Observable<ProductList> {
    return this.http.get<ProductList>(`${this.baseUrl}/query/list/ids/project_id`);
  }

  // please don't delete, its better to have good example of a data structure
  getChartDataStatic(): Observable<any[]> {
    return of([
      {
        "values": [
          {
            "label": 1,
            "value": 100, "color": "#666973"
          },
          {
            "label": 2,
            "value": 120, "color": "#666973"
          },
          {
            "label": 3,
            "value": 179, "color": "#666973"
          }
        ]
      }
    ]);
  }

}
