import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CaseMessageQueryDto} from "../../dto/case-message/case-message.query.dto";
import {CaseMessageDto} from "../../dto/case-message/case-message.dto";
import {Observable} from "rxjs";
import {CaseMessageCreateDto} from "@app/shared/dto/case-message/case-message.create.dto";

@Injectable()
export class CaseMessageService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = '/api/case-messages';
  }

  findAll(queryDto: CaseMessageQueryDto): Observable<CaseMessageDto[]> {
    return this.http.post<CaseMessageDto[]>(this.baseUrl, queryDto);
  }

  countAll(queryDto: CaseMessageQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, queryDto);
  }

  readCaseMessage(caseMessageId: string): Observable<CaseMessageDto> {
    return this.http.get<CaseMessageDto>(`${this.baseUrl}/${caseMessageId}`);
  }

  createCaseMessage(createCaseMessageDto: CaseMessageCreateDto): Observable<CaseMessageDto> {
    return this.http.put<CaseMessageDto>(this.baseUrl, createCaseMessageDto);
  }
}
