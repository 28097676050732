export class ToolSimulationRequestDto {

  "target_usages_per_cycle": number;
  "target_cycles_per_tool": number;
  "weekly_target_usages": number;
  "production_days": Map<string, boolean> = new Map<string, boolean>();
  "sim_times":  Map<string, number> = new Map<string, number>();
  "simulation_weeks": number;
  "active_inventory_thres": number;
  "allow_order": boolean;
  "allow_historical_orders": boolean;
  "return_simulation_log": boolean;
}
