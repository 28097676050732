import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  MachineDocumentationItemDto
} from "@app/shared/dto/machine-documentation/machine-documentation-item.dto";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";

@Injectable()
export class MachineDocumentationService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = "/api/machine-documentations";
  }

  findAll(machineId: string): Observable<MachineDocumentationItemDto[]> {
    return this.http.get<MachineDocumentationItemDto[]>(`${this.baseUrl}/${machineId}`);
  }

  createPresignedFileDownloadRequest(path: string): Observable<PresignedFileRequestDto> {
    const params = new HttpParams().set('path', path);

    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/presigned-download-request`, { params });
  }
}
