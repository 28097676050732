import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ToastService} from "@app/shared/services/toast/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {Toast} from "@app/shared/model/toast/toast";

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() toastPosition = "top-right";

  dvsToast: HTMLDvsToastElement;

  toastSubscription!: Subscription;
  constructor(private toastService: ToastService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.dvsToast = document.querySelector('dvs-toast');

    this.toastSubscription = this.toastService.onAlert(this.id)
    .subscribe(toast => {
      this.dvsToast.Toast({
        header: toast.header ? this.translateService.instant(toast.header, toast.headerParams) : null,
        description: this.translateService.instant(toast.description, toast.descriptionParams),
        timeout: toast.timeout,
        type: toast.type,
      }).then()
    });
  }

  ngOnDestroy(): void {
    this.toastSubscription.unsubscribe();
  }

}
