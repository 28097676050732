import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolOrderQueryDto} from "@app/shared/dto/tool-order/tool-order.query.dto";
import {Observable} from "rxjs";
import {ToolOrderDto} from "@app/shared/dto/tool-order/tool-order.dto";

@Injectable()
export class ToolOrderService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tool-orders';
  }

  findAll(query: ToolOrderQueryDto): Observable<ToolOrderDto[]> {
    return this.http.post<ToolOrderDto[]>(`${this.baseUrl}`, query);
  }

  countAll(query: ToolOrderQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

}
