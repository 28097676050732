import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "@app/shared/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ActionService} from "@app/shared/services/action.service";
import {PreviousRouteService} from "@app/shared/services/previous-route.service";

@Component({
  selector: 'dvs-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'toolsmgmt';
  username: string;

  constructor(private authService: AuthService,
              private router: Router,
              private translate: TranslateService,
              private actionService: ActionService,
              private previousRouteService: PreviousRouteService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.authService.configure();
    this.authService.getUserInfoPromise().then((userInfo) => {
      this.username = userInfo.given_name + " " + userInfo.family_name;
    })
  }

  menuClicked(e: MouseEvent): void {
    this.router.navigate(['/launchpad']).then();
  }

  logout(): void{
    this.authService.logOut();
  }

}
