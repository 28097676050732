import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {ScannerEventConfig, ScannerEventService} from "@app/shared/services/scanner-event.service";

@Injectable({
  providedIn: "root"
})
export class ScannerService implements OnInit, OnDestroy{
  private subject: Subject<string> = new Subject<string>();

  constructor(private scannerEventService: ScannerEventService) {
    const self = this;
    const options: ScannerEventConfig = {
      stopPropagation: true,
      preventDefault: false,
      reactToPaste: true,
      captureEvents: true,
      onScan(scanned: string) {
        return self._onScan(scanned);
      },
    };
    options.ignoreIfFocusOn = ['input', 'textarea', 'dvs-textarea', 'dvs-input', 'dvs-datepicker'];
    scannerEventService.attachTo(options);
  }

  ngOnDestroy(): void {
    if (this.subject) {
      this.subject.complete();
    }
    this.scannerEventService.detach();
  }

  subscribe(callback: (code: string) => void): Subscription {
    return this.subject.subscribe(callback);
  }

  _onScan(code: string) {
    this.subject.next(code);
  }

  ngOnInit(): void {
  }
}
