import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ManufacturerQueryDto} from "@app/shared/dto/manufacturer/manufacturer.query.dto";
import {Observable} from "rxjs";
import {ManufacturerDto} from "@app/shared/dto/manufacturer/manufacturer.dto";

@Injectable()
export class ManufacturerService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/manufacturers'
  }

  findAll(query: ManufacturerQueryDto): Observable<ManufacturerDto[]> {
    return this.http.post<ManufacturerDto[]>(this.baseUrl, query);
  }

}
