import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "@app/shared/services/auth.service";


/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router,
              private authService: AuthService) {
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (!this.authService.isLoggedIn()) {
      this.authService.logIn(state.url);
      return false;
    }

    return this.authService.getUserInfoPromise().then((userInfo) => {
      if (userInfo.status === 'PENDING_REGISTRATION') {
        this._router.navigate(['/pending-registration']);
        return;
      }

      if (route && route.data && route.data['permissions']) {
        if (userInfo && userInfo["permissions"]) {
          for (let i = 0; i < route.data['permissions'].length; i++) {
            if (!userInfo.hasAccess(route.data['permissions'][i]['resource'], route.data['permissions'][i]['permission'])) {
              return false;
            }
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }

    });
  }
}
