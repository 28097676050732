
  <div class="l-container">
    <button id="btn-profile-close" (click)="closeEvent.emit()" class="profile__close"></button>
    <div class="profile__wrap">
      <div class="profile__avatar">{{initials}}</div>
      <span class="profile__name">{{ username }}</span>
      <span class="profile__position" *ngFor="let customer of customers" >
         <button class="profile__btn_active" type="button"  (click)="activateCustomer(customer,true)"> {{customer.name}} </button>
      </span>
      <br/>
      <span class="profile__active_customer_text">Active Customer: <div class="profile__active_customer">{{ activeCustomer?.name }}</div></span>
      <button class="profile__btn" type="button" (click)="logoutEvent.emit()">Log Out</button>
    </div>
  </div>

