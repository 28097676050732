import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface ModalFormButton {
  name: string;
  variant: string;
  click: (data: any) => void;
}
export interface ModalFormAware {
  setOnClose(onClose: EventEmitter<any>);
  getTitle(): string;
  setFormData(formData: any);
}

@Component({
  selector: 'modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: [
    './modal-form.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormComponent implements AfterViewInit, OnDestroy, AfterViewChecked, AfterContentChecked {
  @ViewChild("dynamicView", { read: ViewContainerRef, static: false })
  container;
  private onCloseEventEmitter = new EventEmitter();
  @Input()
  title: string;

  constructor(private dialogRef: MatDialogRef<ModalFormComponent>,
              private resolver: ComponentFactoryResolver,
              private changeDetectionRef: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.onCloseEventEmitter.subscribe(($event) => this.onClose($event));
  }


  onClose(response: any) {
    this.dialogRef.close(response);
  }

  ngAfterViewInit(): void {
    const factory: ComponentFactory<ModalFormAware> = this.resolver.resolveComponentFactory(this.data.catalogFormComponent);
    let ref = this.container.createComponent(factory);

    ref.instance.setOnClose(this.onCloseEventEmitter);

    if (this.data.title) {
      this.title = this.data.title;
    } else {
      this.title = ref.instance.getTitle();
    }

    ref.instance.setFormData(this.data);

  }

  ngOnDestroy(): void {
    this.onCloseEventEmitter.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  ngAfterContentChecked(): void {
  }

}