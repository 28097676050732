import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dvs-api-documentation',
  templateUrl: './api-documentation.component.html',
  styleUrls: ['./api-documentation.component.scss']
})
export class ApiDocumentationComponent implements OnInit {
  @Input() toolProjectId: string;

  private readonly baseUrl: string;
  readonly commonPath: string;

  constructor() {
    this.baseUrl = window.location.origin;
    this.commonPath = `${this.baseUrl}/api/v1/processes`;
  }

  ngOnInit(): void {
    if (!this.toolProjectId) {
      this.toolProjectId = "<toolProjectId>"
    }
  }

}
