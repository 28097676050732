import {
  ArgumentException,
  BinaryBitmap,
  ChecksumException,
  FormatException,
  HybridBinarizer,
  NotFoundException,
  Result
} from '@zxing/library';
import {
  BrowserCodeReader,
  BrowserMultiFormatReader,
  HTMLCanvasElementLuminanceSource,
  HTMLVisualMediaElement,
  IScannerControls
} from '@zxing/browser';
import {BehaviorSubject, Observable} from 'rxjs';
import {ResultAndError} from "@app/shared/components/dmc-scanner/result-and-error";
import LuminanceSource from "@zxing/library/esm/core/LuminanceSource";

const getMediaElementDimensions = (mediaElement:HTMLVisualMediaElement) => {
  if (mediaElement instanceof HTMLVideoElement) {
    return {
      height: mediaElement.videoHeight,
      width: mediaElement.videoWidth,
      clientHeight: mediaElement.clientHeight,
      clientWidth: mediaElement.clientWidth
    };
  }
  if (mediaElement instanceof HTMLImageElement) {
    return {
      height: mediaElement.naturalHeight || mediaElement.height,
      width: mediaElement.naturalWidth || mediaElement.width,
      clientHeight: mediaElement.clientHeight,
      clientWidth: mediaElement.clientWidth
    };
  }
  throw new Error('Couldn\'t find the Source\'s dimentions!');
};

const magnifications = [0, 2, 4, 8, 16];

BrowserCodeReader.drawImageOnCanvas = (canvasElementContext, mediaElement) => {
  const { width, height, clientWidth, clientHeight } = getMediaElementDimensions(mediaElement);

  if (clientWidth > 0 && clientHeight > 0) {
    const idx = (document.getElementById("range") as HTMLInputElement).value;

    const magnification = magnifications[idx];

    const sy  = (height - clientHeight) / 2;
    const sx = (width - clientWidth) / 2;

    let newSx = 0, newSy = 0, newWidth = clientWidth, newHeight = clientHeight;

    if (magnification > 0) {
      newSx     = clientWidth*(magnification-1)/(magnification*2);
      newSy     = clientHeight*(magnification-1)/(magnification*2);
      newWidth  = clientWidth/magnification;
      newHeight = clientHeight/magnification;
    }

    canvasElementContext.clearRect(0, 0, clientWidth, clientHeight);
    canvasElementContext.drawImage(mediaElement, sx + newSx, sy + newSy, newWidth, newHeight, 0, 0, clientWidth, clientHeight);
  }

};

let drawTimeout;
const draw = (video, ctx) => {
  BrowserCodeReader.drawImageOnCanvas(ctx, video);
  drawTimeout = setTimeout(draw, 50, video, ctx);
}

const tryPlayVideoOrig = BrowserCodeReader.tryPlayVideo;
BrowserCodeReader.tryPlayVideo = (mediaElement: HTMLVideoElement) => {
  const { clientWidth, clientHeight } = getMediaElementDimensions(mediaElement);

  const el   = document.getElementById("previewCanvas") as HTMLCanvasElement;
  el.width = clientWidth;
  el.height = clientHeight;

  const ctx  = el.getContext("2d");

  return new Promise<boolean>((resolve, reject) => {
    tryPlayVideoOrig(mediaElement)
    .then((result) => {
      if (drawTimeout) {
        clearTimeout(drawTimeout);
      }
      draw(mediaElement, ctx);
      resolve(result);
    })
    .catch(reason => reject(reason));
  });
}

BrowserCodeReader.createCaptureCanvas = (mediaElement) => {
  if (!mediaElement) {
    throw new ArgumentException('Cannot create a capture canvas without a media element.');
  }
  if (typeof document === 'undefined') {
    throw new Error('The page "Document" is undefined, make sure you\'re running in a browser.');
  }
  const canvasElement = document.createElement('canvas');
  const { clientWidth, clientHeight } = getMediaElementDimensions(mediaElement);

  canvasElement.style.width   = clientWidth + 'px';
  canvasElement.style.height  = clientHeight + 'px';
  canvasElement.width         = clientWidth;
  canvasElement.height        = clientHeight;

  return canvasElement;
};


/**
 * Based on zxing-typescript BrowserCodeReader
 */
export class BrowserMultiFormatContinuousReader extends BrowserMultiFormatReader {

  private invertSource: boolean = false;

  private scale: number = 0.5;
  /**
   * Allows to call scanner controls API while scanning.
   * Will be undefined if no scanning is runnig.
   */
  protected scannerControls: IScannerControls;

  /**
   * Returns the code reader scanner controls.
   */
  public getScannerControls(): IScannerControls {
    if (!this.scannerControls) {
      throw new Error('No scanning is running at the time.');
    }
    return this.scannerControls;
  }

  /**
   * Starts the decoding from the current or a new video element.
   *
   * @param deviceId The device's to be used Id
   * @param previewEl A new video element
   */
  public async scanFromDeviceObservable(
    deviceId?: string,
    previewEl?: HTMLVideoElement
  ): Promise<Observable<ResultAndError>> {

    const scan$ = new BehaviorSubject<ResultAndError>({});
    let ctrls;

    try {
      ctrls = await this.decodeFromVideoDevice(deviceId, previewEl, (result, error) => {

        if (!error) {
          scan$.next({ result });
          return;
        }

        const errorName = error.name;

        // stream cannot stop on fails.
        if (
          // scan Failure - found nothing, no error
          errorName === NotFoundException.name ||
          // scan Error - found the QR but got error on decoding
          errorName === ChecksumException.name ||
          errorName === FormatException.name ||
          error.message.includes('No MultiFormat Readers were able to detect the code.')
        ) {
          scan$.next({ error });
          return;
        }

        // probably fatal error
        scan$.error(error);
        this.scannerControls.stop();
        this.scannerControls = undefined;
        return;
      });

      this.scannerControls = {
        ...ctrls,
        stop() {
          ctrls.stop();
          scan$.complete();
        },
      };
    } catch (e) {
      scan$.error(e);
      this.scannerControls?.stop();
      this.scannerControls = undefined;
    }

    return scan$.asObservable();
  }



  decodeFromCanvas(canvas: HTMLCanvasElement): Result {
    const allWidth  = canvas.width;
    const allHeight = canvas.height;

    const squareSize = Math.min(allWidth, allHeight) * this.scale;

    document.getElementById('scan-area').style.width = `${squareSize}px`;
    document.getElementById('scan-area').style.height = `${squareSize}px`;

    let luminanceSource: LuminanceSource = new HTMLCanvasElementLuminanceSource(canvas);

    if(this.invertSource) {
      luminanceSource = luminanceSource.invert();
    }
    this.invertSource = !this.invertSource;

    const hybridBinarizer = new HybridBinarizer(luminanceSource);

    const binaryBitmap = new BinaryBitmap(hybridBinarizer);

    return this.decodeBitmap(binaryBitmap);
  }


}
