import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CaseHistoryQueryDto} from "../../../../../../consumer-portal/src/app/shared/dto/case-history/case-history.query.dto";
import {CaseHistoryDto} from "../../../../../../consumer-portal/src/app/shared/dto/case-history/case-history.dto";
import {Observable} from "rxjs";

@Injectable()
export class CaseHistoryService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = '/api/case-history'
  }

  findAll(query: CaseHistoryQueryDto): Observable<CaseHistoryDto[]> {
    return this.http.post<CaseHistoryDto[]>(this.baseUrl, query);
  }

  countAll(queryDto: CaseHistoryQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, queryDto);
  }
}
