import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolUnmountReasonQueryDto} from "@app/shared/dto/tool-unmount-reason/tool-unmount-reason.query.dto";
import {ToolUnmountReasonDto} from "@app/shared/dto/tool-unmount-reason/tool-unmount-reason.dto";
import {Observable} from "rxjs";

@Injectable()
export class ToolUnmountReasonService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tools/unmount-reasons';
  }

  findAll(query: ToolUnmountReasonQueryDto): Observable<ToolUnmountReasonDto[]> {
    return this.http.post<ToolUnmountReasonDto[]>(this.baseUrl, query);
  }
}
