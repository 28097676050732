import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PartQueryDto} from "@app/shared/dto/part/part.query.dto";
import {PartDto} from "@app/shared/dto/part/part.dto";
import {CreatePartDto} from "@app/shared/dto/part/create-part.dto";
import {Observable} from "rxjs";
import {ToolProjectImportRequestDto} from "@app/shared/dto/tool-project/tool-project-import.request.dto";
import {ToolProjectDto} from "@app/shared/dto/tool-project/tool-project.dto";

@Injectable()
export class PartService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/parts';
  }

  findAll(query: PartQueryDto): Observable<PartDto[]> {
    return this.http.post<PartDto[]>(this.baseUrl, query);
  }

  create(createPart: CreatePartDto): Observable<PartDto> {
    return this.http.put<PartDto>(this.baseUrl, createPart);
  }

  read(id: string): Observable<PartDto> {
    return this.http.get<PartDto>(`${this.baseUrl}/${id}`);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

}
