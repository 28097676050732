import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {AuthService} from "@app/shared/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ErrorCode} from "@app/shared/error/error-code";
import moment from "moment";
import {ToastService} from "@app/shared/services/toast/toast.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private toastService: ToastService) {
  }

  /**
   * Interceptor intercepts the responses, and then process based on the received status code
   */
  intercept(request: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authService.logOut();
      }

      if (err.status === 400) {
        if (err.error.errorCode) {
          const additionalInfo = err.error.additionalInfo;
          if (additionalInfo) {
            Object.keys(additionalInfo).forEach((key) => {
              if (key.toUpperCase().endsWith('DATE') && additionalInfo[key]) {
                additionalInfo[key] = moment(additionalInfo[key] + 'Z').format('DD.MM.YYYY HH:mm:ss')
              }
            })
          }
          this.toastService.error("", "ERRORS." + err.error.errorCode, { descriptionParams: err.error.additionalInfo});
        } else {
          this.toastService.error("", "ERRORS." + ErrorCode.E900);
        }
      }

      return throwError(err);
    }))
  }
}
