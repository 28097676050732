import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineLicenceUpdateDto} from "@app/shared/dto/machine-edge-device/machine-licence-update.dto";
import {Observable} from "rxjs";
import {MachineLicenceQueryDto} from '@app/shared/dto/machine-licence/machine-licence.query.dto';
import {MachineLicenceDto} from '@app/shared/dto/machine-licence/machine-licence.dto';
import {MachineLicenceCreateDto} from '@app/shared/dto/machine-licence/machine-licence.create.dto';
import {MachineLicenceContentDto} from '@app/shared/dto/machine-licence/machine-licence-content.dto';

@Injectable()
export class MachineLicenceService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/machine-licences';
  }

  findAll(query: MachineLicenceQueryDto): Observable<MachineLicenceDto[]> {
    return this.http.post<MachineLicenceDto[]>(`${this.baseUrl}`, query);
  }

  countAll(query: MachineLicenceQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  create(createMachineLicence: MachineLicenceCreateDto): Observable<MachineLicenceContentDto> {
    return this.http.put<MachineLicenceContentDto>(`${this.baseUrl}`, createMachineLicence);
  }

  update(id: string, updateMachineLicence: MachineLicenceUpdateDto, fields: string[]): Observable<void> {
    const params = {};
    if (fields.length > 0) {
      params['fields'] = fields;
    }
    return this.http.post<void>(`${this.baseUrl}/${id}`, updateMachineLicence, {params});
  }
}
