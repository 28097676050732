import {Resource} from "@app/shared/dto/resource/resource";

export class UserInfoDto {
  id: string;
  sub: string;
  status?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
  customer_ids?: string[]
  active_customer_id?: string;
  permissions?: string[];

  public hasAccess(resource: Resource, permission: string): boolean {
    let targetPermissionMask;

    try {
      targetPermissionMask = parseInt(permission, 2);
    } catch (e) {
      // permission name is not a binary string
      return false;
    }

    if (targetPermissionMask < 0 || targetPermissionMask > 31) {
      return false;
    }

    for (let i = 0; i < this.permissions.length; i++) {
      const userResourcePermission = this.permissions[i].split('_');
      const resourceId = parseInt(userResourcePermission[0]);

      if (resourceId === resource ||  resourceId === Resource.ALL_DATA) {
        let permissionMask;
        try {
          permissionMask = parseInt(userResourcePermission[1], 2);
        } catch (e) {
          // permission name is not a binary string
          return false;
        }

        if ((targetPermissionMask & permissionMask) == targetPermissionMask) {
          return true;
        }

      }
    }

    return false;
  }

}
