import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AccessKeyDto} from "@app/shared/dto/access-key/access-key.dto";
import {AccessKeyQueryDto} from "@app/shared/dto/access-key/access-key.query.dto";
import {NewAccessKeyDto} from "@app/shared/dto/access-key/new-access-key.dto";
import {AccessKeyStatusEnum} from "@app/shared/dto/access-key/access-key-status.enum";

@Injectable()
export class AccessKeyService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = '/api/access-keys';
    }

    public findAll(): Observable<AccessKeyDto[]> {
        return this.http.post<AccessKeyDto[]>(this.baseUrl, new AccessKeyQueryDto());
    }

    createAccessKey(): Observable<NewAccessKeyDto> {
        return this.http.post<NewAccessKeyDto>(this.baseUrl + '/new', {});
    }

    changeStatus(id: string, status: AccessKeyStatusEnum): Observable<void> {
        return this.http.post<void>(this.baseUrl + '/' + id + '/status/' + status, {});
    }
}
