<input *ngIf="!visible" type="file" style="display: none;" (change)="onFileSelected($event)" #fileUploadInputField>
<dvs-filepicker
  [ngClass]="visible ? '' : 'hidden'"
  *ngIf="visible"
  [drop]="drop"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [accept]="accept"
  (changed)="onFileSelected($event)">
</dvs-filepicker>
<ng-container *ngIf="showProgress">
  <div *ngFor="let file of files">
    <div class="upload-container" *ngIf="file.showProgress">
      <div class="progress_bar_file_name">{{ file.name }}</div>
      <dvs-single-bar-chart
        style="--bar-size: 12px"
        [value]="file.percentDone"
        [labelPosition]="'start'"
        [direction]="'left-to-right'">
      </dvs-single-bar-chart>
    </div>
  </div>
</ng-container>

