import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {Observable} from "rxjs";
import {SupplierDto} from "@app/shared/dto/supplier/supplier.dto";
import {SupplierQueryDto} from "@app/shared/dto/supplier/supplier.query.dto";


@Injectable()
export class SupplierService {
  private baseUrl: string

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/suppliers'
  }

  findAll(query: SupplierQueryDto): Observable<SupplierDto[]> {
    return this.http.post<SupplierDto[]>(this.baseUrl, query);
  }


}
