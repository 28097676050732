import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class UserService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/users';
  }

  setActiveCustomer(customerId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/active-customer`, {customerId});
  }

  signOutGlobally() {
    return this.http.get<void>(`${this.baseUrl}/sign-out-globally`);
  }

  removeUser(userId: string, customerId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${userId}/customers/${customerId}`);
  }

}