<div class="modal-content">
  <ng-container [ngSwitch]="contentType">

    <ng-container *ngSwitchCase="'string'">
      <div class="box">
        <div [innerHTML]="content"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>

  </ng-container>
</div>

<!-- You can also add a global close button -->
<button (click)="close()" class="modal-close is-large" aria-label="close"></button>
