import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RoleQueryDto} from "@app/shared/dto/role/role.query.dto";
import {Observable} from "rxjs";
import {RoleDto} from "@app/shared/dto/role/role.dto";

@Injectable()
export class RoleService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/roles';
  }

  findAll(query: RoleQueryDto): Observable<RoleDto[]> {
    return this.http.post<RoleDto[]>(this.baseUrl, query);
  }

  countAll(query: RoleQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

}