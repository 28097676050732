import {FractionDigitsPipe} from "@app/shared/pipes/fraction-digits.pipe";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SafePipe} from "@app/shared/pipes/safe.pipe";
import {FileSizePipe} from "@app/shared/pipes/file-size.pipe";

const pipes = [
  FractionDigitsPipe,
  SafePipe,
  FileSizePipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {

}
