import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "@app/shared/components/shared-components.module";
import {AuthGuard} from "@app/shared/guard/auth.guard";
import {ToolService} from "@app/shared/services/tool.service";
import {AccessKeyService} from "@app/shared/services/access-key.service";
import {ToolEventService} from "@app/shared/services/tool-event.service";
import {FileService} from "@app/shared/services/file.service";
import {SharedPipesModule} from "@app/shared/pipes/shared-pipes-module";
import {MachineService} from "@app/shared/services/machine/machine.service";
import {ToolUsageService} from "@app/shared/services/tool-usage.service";
import {ToolUnmountReasonService} from "@app/shared/services/tool-unmount-reason.service";
import {PartService} from "@app/shared/services/part.service";
import {CustomerService} from "@app/shared/services/customer/customer.service";
import {ToolProjectService} from "@app/shared/services/tool-project.service";
import {ProductionService} from "@app/shared/services/production/production.service";
import {ToolStatusService} from "@app/shared/services/tool-status.service";
import {SharedDirectivesModule} from "@app/shared/directives/shared-directives.module";
import {SupplierService} from "@app/shared/services/supplier.service";
import {UserService} from "@app/shared/services/user/user.service";
import {LocalStorageService} from "@app/shared/services/localstorage.service";
import {CustomerApplicationService} from "@app/shared/services/customer/customer-application.service";
import {MachineEdgeDeviceService} from "@app/shared/services/machine-edge-device/machine-edge-device.service";
import {ToolsKpiService} from "@app/shared/services/tools-kpi.service";
import {CustomerUserService} from "@app/shared/services/user/customer-user.service";
import {UserRoleService} from "@app/shared/services/user-role/user-role.service";
import {RoleService} from "@app/shared/services/role/role.service";
import {InvitationService} from "@app/shared/services/invitation/invitation.service";
import {MachineDocumentationService} from "@app/shared/services/machine-documentation/machine-documentation.service";
import {MachineComponentService} from "@app/shared/services/machine-component/machine-component.service";
import {SoftwareDownloadService} from "@app/shared/services/software-download/software-download.service";
import {TenantGuard} from "@app/shared/guard/tenant.guard";
import {DrawingNumberService} from "@app/shared/services/drawing-number.service";
import {EdgeDeviceDeploymentService} from "@app/shared/services/edge-device-deployment/edge-device-deployment.service";
import { FormErrorPipe } from './pipes/form-error.pipe';
import {ToolOrderService} from "@app/shared/services/tool-order/tool-order.service";
import {ManufacturerService} from "@app/shared/services/manufacturer.service";
import {MachineMappingService} from "@app/shared/services/machine-mapping/machine-mapping.service";
import {MachineCaseService} from "@app/shared/services/machine-case/machine-case.service";
import {CaseHistoryService} from "@app/shared/services/case-history/case-history.service";
import {CaseMessageService} from "@app/shared/services/case-message/case-message.service";

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  exports: [
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    FormErrorPipe,
  ],
  providers: [
    AuthGuard,
    TenantGuard,
    ToolService,
    SupplierService,
    ToolUsageService,
    AccessKeyService,
    ToolEventService,
    MachineService,
    MachineEdgeDeviceService,
    ProductionService,
    ToolStatusService,
    ToolUnmountReasonService,
    FileService,
    PartService,
    UserService,
    CustomerService,
    CustomerApplicationService,
    ToolProjectService,
    LocalStorageService,
    ToolsKpiService,
    CustomerUserService,
    RoleService,
    UserRoleService,
    InvitationService,
    MachineDocumentationService,
    MachineComponentService,
    SoftwareDownloadService,
    EdgeDeviceDeploymentService,
    DrawingNumberService,
    ToolOrderService,
    ManufacturerService,
    MachineMappingService,
    MachineCaseService,
    CaseHistoryService,
    CaseMessageService
  ],
  declarations: [
    FormErrorPipe
  ]
})
export class SharedModule {
}
