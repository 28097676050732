import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CustomerApplicationDto} from "@app/shared/dto/customer-application/customer-application.dto";
import {CustomerApplicationQueryDto} from "@app/shared/dto/customer-application/customer-application.query.dto";

@Injectable()
export class CustomerApplicationService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/customer-applications';
  }

  findAll(query: CustomerApplicationQueryDto): Observable<CustomerApplicationDto[]>{
    return this.http.post<CustomerApplicationDto[]>(`${this.baseUrl}`, query);
  }

  openApplication(applicationId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${applicationId}/open`, {});
  }
}
