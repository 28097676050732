import {Component} from "@angular/core";
import {AuthService} from "@app/shared/services/auth.service";
import {ActivationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationDto} from "@app/shared/dto/application/application.dto";
import {UserService} from "@app/shared/services/user/user.service";

@Component({
  selector: 'dvs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  page = "Home";

  constructor(private authService: AuthService,
              private userService: UserService,
              private translateService: TranslateService,
              private router: Router,
              ) {

    this.router.events.subscribe((event) => {
      // 2. Start with ActivationEnd, experiment with the other events
      if (event instanceof ActivationEnd) {
        // 3. if your data exists assign it for use!
        if (event.snapshot.data['title']) {
          this.page = this.translateService.instant(event.snapshot.data['title']);
        }
      }
    });

    this.authService.configure();
    this.authService.getUserInfoPromise().then();

  }

  logout(): void {
    this.authService.logOut();
  }

  openApplication($event: CustomEvent<ApplicationDto>) {
    let applicationDto = $event.detail;
    this.router.navigate([applicationDto.reference]).then();
  }
}
