import {HttpClient, HttpParams} from "@angular/common/http";
import {ToolEventCreateUserCommentDto} from "@app/shared/dto/tool-event/tool-event-create-user-comment.dto";
import {Injectable} from "@angular/core";
import {BaseToolEventDto} from "@app/shared/dto/tool-event/base-tool-event.dto";
import {Observable} from "rxjs";

@Injectable()
export class ToolEventService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tool-events';
  }

  addToolEvent(event: ToolEventCreateUserCommentDto): Observable<BaseToolEventDto> {
    return this.http.put<BaseToolEventDto>(this.baseUrl, event);
  }

  findAll(customerId: string, toolId: string, limit?: number, lastEvaluatedSortKey?: number): Observable<BaseToolEventDto[]> {

    let params = new HttpParams().set('customerId', customerId).set("toolId", toolId);
    if (limit !== undefined && limit !== null) {
      params = params.set("limit", limit.toString());
    }
    if (lastEvaluatedSortKey !== undefined && lastEvaluatedSortKey !== null) {
      params = params.set("lastEvaluatedSortKey", lastEvaluatedSortKey.toString());
    }

    return this.http.get<BaseToolEventDto[]>(this.baseUrl, { params });
  }

}
