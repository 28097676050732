import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {
  MachineComponentDto
} from "@app/shared/dto/machine-component/machine-component.dto";
import {Observable} from "rxjs";
import {FileMetadataDto} from "@app/shared/dto/file/file-metadata.dto";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";

@Injectable()
export class MachineComponentService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = "/api/machine-components"
  }

  findAll(machineId: string, language: string): Observable<MachineComponentDto[]> {
    return this.http.get<MachineComponentDto[]>(`${this.baseUrl}/${machineId}?lang=${language}`);
  }

  findAllFiles(machineId: string): Observable<FileMetadataDto[]> {
    return this.http.get<FileMetadataDto[]>(`${this.baseUrl}/${machineId}/files`);
  }

  createPresignedFileDownloadRequest(path: string): Observable<PresignedFileRequestDto> {
    const params = new HttpParams().set('path', path);

    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/presigned-download-request`, { params });
  }

}
