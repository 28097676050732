import {BaseQueryDto} from "@app/shared/dto/common/base.query.dto";

export class CustomerQueryDto extends BaseQueryDto{
  nameLike: string

  constructor() {
    super();
    this.queryType = 'customer';
  }

}
