import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "@app/shared/services/auth.service";
import {UserService} from "@app/shared/services/user/user.service";
import {CustomerUserService} from "@app/shared/services/user/customer-user.service";
import {CustomerUserQueryDto} from "@app/shared/dto/user/customer-user.query.dto";

@Injectable()
export class TenantGuard implements CanActivate {

  constructor(private authService: AuthService,
              private userService: UserService,
              private customerUserService: CustomerUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      const tenantId: string = route.queryParams['tenant'];
      let activeCustomerId: string;

      if (tenantId) {
        let canAccessTenant = false;
        this.authService.getUserInfoPromise().then((userInfo) => {
          activeCustomerId = userInfo.active_customer_id;

          if (activeCustomerId == tenantId) {
            // requested tenant is current tenant
            resolve(true);
          } else {
            // check if user has access to requested tenant
            const customerUserQuery = new CustomerUserQueryDto();
            customerUserQuery.queryType = 'customer-user';
            customerUserQuery.userId = userInfo.id;
            this.customerUserService.findAll(customerUserQuery).subscribe((customerUsers) => {
              canAccessTenant = !!customerUsers.find(customerUser => customerUser.customer.id == tenantId);

              if (canAccessTenant) {
                // Switch active customer to requested tenant id
                this.userService.setActiveCustomer(tenantId).subscribe(() => {
                  window.location.reload();
                  resolve(true);
                }, () => {
                  resolve(false);
                });
              } else {
                resolve(false);
              }
            })
          }
        })
      } else {
        // setting no tenant id should be always possible
        resolve(true);
      }
    });
  }

}
