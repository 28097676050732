import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineEdgeDeviceQueryDto} from "@app/shared/dto/machine-edge-device/machine-edge-device.query.dto";
import {MachineLicenceUpdateDto} from "@app/shared/dto/machine-edge-device/machine-licence-update.dto";
import {MachineEdgeDeviceCreateDto} from "@app/shared/dto/machine-edge-device/machine-edge-device.create.dto";
import {MachineEdgeDeviceDto} from "@app/shared/dto/machine-edge-device/machine-edge-device.dto";
import {Observable} from "rxjs";
import {
  EdgeDeviceProvisioningClaimDataDto
} from "@app/shared/dto/machine-edge-device/edge-device-provisioning-claim-data.dto";

@Injectable()
export class MachineEdgeDeviceService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/machine-edge-devices';
  }

  findAll(query: MachineEdgeDeviceQueryDto) {
    return this.http.post<MachineEdgeDeviceDto[]>(`${this.baseUrl}`, query);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  create(createMachineEdgeDevice: MachineEdgeDeviceCreateDto): Observable<MachineEdgeDeviceDto> {
    return this.http.put<MachineEdgeDeviceDto>(`${this.baseUrl}`, createMachineEdgeDevice);
  }

  update(id: string, updateEdgeDevice: MachineLicenceUpdateDto, fields: string[]): Observable<void> {
    const params = {};
    if (fields.length > 0) {
      params['fields'] = fields;
    }
    return this.http.post<void>(`${this.baseUrl}/${id}`, updateEdgeDevice, {params});
  }

  createProvisioningClaim(edgeDeviceId: string): Observable<EdgeDeviceProvisioningClaimDataDto> {
    return this.http.post<EdgeDeviceProvisioningClaimDataDto>(`${this.baseUrl}/${edgeDeviceId}/provisioning-claim`, {});
  }

  downloadSoftwareUpdate(edgeDeviceId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${edgeDeviceId}/software-update`, {});
  }

}
