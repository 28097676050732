import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

export interface ScanError {
  message,
  scanCode?,
  scanDuration?,
  avgTimeByChar?,
  minLength?
}
export class ScannerEventConfig {
  /**
   * Callback after detection of a successful scanning:  (code: string, count: number) => ()
   */

  onScan?: (code: string, count: number) => void;
  /**
   * Callback after detection of an unsuccessful scanning (scanned string in parameter)
   */
  onScanError?: (debugInfo: any) => void;
  /**
   * Callback after receiving and processing a char (scanned char in parameter)
   */
  onKeyProcess?: (char: string, event: any) => void;
  /**
   * Callback after detecting a keyDown (key char in parameter) - in contrast to onKeyProcess, this fires for non-character keys like tab, arrows, etc. too!
   */
  onKeyDetect?: (keyCode: number, event: any) => void;
  /**
   * Callback after receiving a value on paste, no matter if it is a valid code or not
   */
  onPaste?: (pasted: string, event: any) => void;
  /**
   * Custom function to decode a keydown event into a character. Must return decoded character or NULL if the given event should not be processed.
   */
  keyCodeMapper?: (event: any) => void;
  /**
   * Callback after detection of a successful scan while the scan button was pressed and held down
   */
  onScanButtonLongPress?: () => void;
  /**
   * Key code of the scanner hardware button (if the scanner button acts as a key itself)
   */
  scanButtonKeyCode?: boolean;
  /**
   * How long (ms) the hardware button should be pressed, until a callback gets executed
   */
  scanButtonLongPressTime?: number;
  /**
   * Wait duration (ms) after keypress event to check if scanning is finished
   */
  timeBeforeScanTest?: number;
  /**
   * Average time (ms) between 2 chars. Used to differentiate between keyboard typing and scanning
   */
  avgTimeByChar?: number;
  /**
   * Minimum length for a scanning
   */
  minLength?: number;
  /**
   * Chars to remove and means end of scanning
   */
  suffixKeyCodes?: number[];
  /**
   * Chars to remove and means start of scanning
   */
  prefixKeyCodes?: [];
  /**
   * do not handle scans if the currently focused element matches this selector or object
   */
  ignoreIfFocusOn?: any;
  /**
   * Stop immediate propagation on keypress event
   */
  stopPropagation?: boolean;
  /**
   * Prevent default action on keypress event
   */
  preventDefault?: boolean;
  /**
   * Get the events before any listeners deeper in the DOM
   */
  captureEvents?: boolean;
  /**
   * look for scan input in keyboard events
   */
  reactToKeydown?: boolean;
  /**
   * look for scan input in paste events
   */
  reactToPaste?: boolean;
  /**
   * Quantity of Items put out to onScan in a single scan
   */
  singleScanQty?: number;

}

@Injectable({
  providedIn: 'root'
})
export class ScannerEventService {
  private scannerEventConfig: ScannerEventConfig = {
    onScan: (code: string, count: number) => {},
    onScanError: (debugInfo: any) => {},
    onKeyProcess: (char: string, event: any) => {},
    onKeyDetect: (keyCode: number, event: any) => {},
    onPaste: (pasted: string, event: any) => {},
    keyCodeMapper: (event: KeyboardEvent) => { return this.decodeKeyEvent(event)},
    onScanButtonLongPress: () => {},
    scanButtonKeyCode: false,
    scanButtonLongPressTime: 500,
    timeBeforeScanTest: 100,
    avgTimeByChar: 30,
    minLength: 6,
    suffixKeyCodes: [9, 13],
    prefixKeyCodes: [],
    ignoreIfFocusOn: false,
    stopPropagation: false,
    preventDefault: false,
    captureEvents: false,
    reactToKeydown: true,
    reactToPaste: false,
    singleScanQty: 1
  }

  private scannerDetectionData;
  private repeatingEnterPressed = false;
  private resetRepeatingEnterPressedTimeout;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  private resetRepeatingEnterPressed() {
    this.repeatingEnterPressed = false;
    this.resetRepeatingEnterPressedTimeout = undefined;
  }

  public attachTo(origOptions: ScannerEventConfig) {
    const options = this.mergeOptions(this.scannerEventConfig, origOptions);

    this.scannerDetectionData = {
      options: options,
      vars: {
        firstCharTime: 0,
        lastCharTime: 0,
        accumulatedString: '',
        testTimer: false,
        longPressTimerStart: 0,
        longPressed: false
      }
    };

    // initializing handlers (based on settings)
    if (options.reactToPaste === true) {
      this.document.addEventListener("paste", (e) => this.handlePaste(e), options.captureEvents);
    }

    if (options.scanButtonKeyCode !== false){
      this.document.addEventListener("keyup", (e) => this.handleKeyUp(e), options.captureEvents);
    }
    if (options.reactToKeydown === true || options.scanButtonKeyCode !== false){
      this.document.addEventListener("keydown", (e) => this.handleKeyDown(e), options.captureEvents);
    }
  }

  /**
   *
   * @param DomElement oDomElement
   * @return Object
   */
  getOptions(){
    return this.scannerDetectionData.options;
  }

  /**
   *
   * @param DomElement oDomElement
   * @param Object options
   * @return self
   */
  setOptions(options){
    // check if some handlers need to be changed based on possible option changes
    switch (this.scannerDetectionData.options.reactToPaste){
      case true:
        if (options.reactToPaste === false){
          this.document.removeEventListener("paste", this.handlePaste);
        }
        break;
      case false:
        if (options.reactToPaste === true){
          this.document.addEventListener("paste", this.handlePaste);
        }
        break;
    }

    switch (this.scannerDetectionData.options.scanButtonKeyCode){
      case false:
        if (options.scanButtonKeyCode !== false){
          this.document.addEventListener("keyup", this.handleKeyUp);
        }
        break;
      default:
        if (options.scanButtonKeyCode === false){
          this.document.removeEventListener("keyup", this.handleKeyUp);
        }
        break;
    }

    // merge old and new options
    this.scannerDetectionData.options = this.mergeOptions(this.scannerDetectionData.options, options);

    // reinitiallize
    this.reinitialize();
    return this;
  }

  /**
   * Returns TRUE if the scanner is currently in the middle of a scan sequence.
   *
   * @param DomElement
   * @return boolean
   */
  isScanInProgressFor() {
    return this.scannerDetectionData.vars.firstCharTime > 0;
  }

  /**
   * Returns TRUE if onScan is attached to the given DOM element and FALSE otherwise.
   *
   * @param DomElement
   * @return boolean
   */
  isAttachedTo() {
    return (this.scannerDetectionData !== undefined);
  }

  detach() {
    // detaching all used events
    if (this.scannerDetectionData.options.reactToPaste){
      this.document.removeEventListener("paste", this.handlePaste);
    }
    if (this.scannerDetectionData.options.scanButtonKeyCode !== false){
      this.document.removeEventListener("keyup", this.handleKeyUp);
    }
    this.document.removeEventListener("keydown", this.handleKeyDown);

    // clearing data off DomElement
    this.scannerDetectionData = undefined;
    return;
  }

  /**
   * Merges default and provided options
   * @param defaults default event config
   * @param options provided config
   * @private
   */
  private mergeOptions(defaults: ScannerEventConfig, options: ScannerEventConfig): ScannerEventConfig {
    const extended = new ScannerEventConfig();

    let prop;
    for (prop in defaults) {
      if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
        extended[prop] = defaults[prop];
      }
    }
    for (prop in options) {
      if (Object.prototype.hasOwnProperty.call(options, prop)) {
        extended[prop] = options[prop];
      }
    }

    return extended;
  }

  private handlePaste(e: ClipboardEvent) {
    const options     = this.scannerDetectionData.options;
    const vars        = this.scannerDetectionData.vars;
    const pasteString = e.clipboardData.getData('text');

    // if the focus is on the ignored element, abort
    if (this.isFocusOnIgnoredElement()){
      return;
    }

    e.preventDefault();

    if (options.stopPropagation) {
      // e.stopPropagation();
      e.stopImmediatePropagation();
    }

    options.onPaste.call(this, pasteString, event);

    vars.firstCharTime = 0;
    vars.lastCharTime = 0;

    // validate the string
    this.validateScanCode(pasteString);

    return;
  }

  private isFocusOnIgnoredElement() {
    const ignoreSelectors = this.scannerDetectionData.options.ignoreIfFocusOn;

    if (!ignoreSelectors) {
      return false;
    }

    const focused = this.document.activeElement;

    // checks if ignored element is an array, and if so it checks if one of the elements of it is an active one
    if (Array.isArray(ignoreSelectors)){
      for(let i=0; i<ignoreSelectors.length; i++){
        if(focused.matches(ignoreSelectors[i]) === true){
          return true;
        }
      }
      // if the option consists of an single element, it only checks this one
    } else if (focused.matches(ignoreSelectors)){
      return true;
    }

    // if the active element is not listed in the ignoreIfFocusOn option, return false
    return false;
  }

  /**
   * Validates the scan code accumulated by the given DOM element and fires the respective events.
   * @param scanCode
   * @private
   */
  private validateScanCode(scanCode: string) {
    const scannerData     = this.scannerDetectionData;
    const options         = scannerData.options;
    const singleScanQty   = scannerData.options.singleScanQty;
    const firstCharTime   = scannerData.vars.firstCharTime;
    const lastCharTime    = scannerData.vars.lastCharTime;

    let scanError: ScanError;
    let oEvent;

    switch(true){

      // detect codes that are too short
      case (scanCode.length < options.minLength):
        scanError = {
          message: "Received code is shorter than minimal length"
        };
        break;

      // detect codes that were entered too slow
      case ((lastCharTime - firstCharTime) > (scanCode.length * options.avgTimeByChar)):
        scanError = {
          message: "Received code was not entered in time"
        };
        break;

      // if a code was not filtered out earlier it is valid
      default:
        scanCode = scanCode.trim();
        options.onScan.call(this, scanCode, singleScanQty);
        oEvent = new CustomEvent(
          'scan',
          {
            detail: {
              scanCode: scanCode,
              qty: singleScanQty
            }
          }
        );
        this.document.dispatchEvent(oEvent);
        this.reinitialize();

        return true;
    }

    // If an error occurred (otherwise the method would return earlier) create an object for error detection
    scanError.scanCode      = scanCode;
    scanError.scanDuration  = lastCharTime - firstCharTime;
    scanError.avgTimeByChar = options.avgTimeByChar;
    scanError.minLength     = options.minLength;

    options.onScanError.call(this, scanError);

    oEvent = new CustomEvent(
      'scanError',
      {detail: scanError}
    );

    this.document.dispatchEvent(oEvent);

    this.reinitialize();

    return false;
  }

  private reinitialize() {
    const vars = this.scannerDetectionData.vars;
    vars.firstCharTime = 0;
    vars.lastCharTime = 0;
    vars.accumulatedString = '';

    return;
  }

  /**
   * Transforms key codes into characters.
   *
   * By default, only the follwing key codes are taken into account
   * - 48-90 (letters and regular numbers)
   * - 96-105 (numeric keypad numbers)
   * - 106-111 (numeric keypad operations)
   *
   * All other keys will yield empty strings!
   *
   * The above keycodes will be decoded using the KeyboardEvent.key property on modern
   * browsers. On older browsers the method will fall back to String.fromCharCode()
   * putting the result to upper/lower case depending on KeyboardEvent.shiftKey if
   * it is set.
   *
   * @return string
   * @param e keyboard event
   */
  private decodeKeyEvent(e: KeyboardEvent) {
    const code = this.getNormalizedKeyNum(e);
    switch (true) {
      case code >= 48 && code <= 90: // numbers and letters
      case code == 32: // Space
      case code == 189: // Dash
      case code == 190: // Period
      case code >= 106 && code <= 111: // operations on numeric keypad (+, -, etc.)
        if (e.key !== undefined && e.key !== '') {
          return e.key;
        }

        let decoded = String.fromCharCode(code);
        switch (e.shiftKey) {
          case false: decoded  = decoded.toLowerCase(); break;
          case true: decoded   = decoded.toUpperCase(); break;
        }
        return decoded;
      case code >= 96 && code <= 105: // numbers on numeric keypad
        return code - 96;
    }
    return '';
  }

  private handleKeyUp(e: KeyboardEvent) {
    // if the focus is on an ignored element, abort
    if (this.isFocusOnIgnoredElement()){
      return;
    }

    const keyCode = this.getNormalizedKeyNum(e);

    // if hardware key is not being pressed anymore stop the timeout and reset
    if (keyCode == this.scannerDetectionData.options.scanButtonKeyCode){
      clearTimeout(this.scannerDetectionData.vars.longPressTimer);
      this.scannerDetectionData.vars.longPressed = false;
    }
    return;
  }

  private handleKeyDown(e: KeyboardEvent) {
    const keyCode = this.getNormalizedKeyNum(e);
    const options = this.scannerDetectionData.options;
    const vars    = this.scannerDetectionData.vars;

    // prevent repeating enter key codes in DMC
    if (keyCode == 13) {
      if (this.repeatingEnterPressed) {
        e.preventDefault();
        e.stopImmediatePropagation();
        console.log("Ignored repeating enter code");
        clearTimeout(this.resetRepeatingEnterPressedTimeout);
        this.resetRepeatingEnterPressedTimeout = setTimeout(() => this.resetRepeatingEnterPressed(), 50);
        return;
      } else if (vars.accumulatedString) {
        this.repeatingEnterPressed = true;
        clearTimeout(this.resetRepeatingEnterPressedTimeout);
        this.resetRepeatingEnterPressedTimeout = setTimeout(() => this.resetRepeatingEnterPressed(), 50);
      }
    }

    let scanFinished = false;

    if (options.onKeyDetect.call(this, keyCode, e) === false) {
      return;
    }

    if (this.isFocusOnIgnoredElement()){
      return;
    }

    // If it's just the button of the scanner, ignore it and wait for the real input
    if(options.scanButtonKeyCode !== false && keyCode==options.scanButtonKeyCode) {

      // if the button was first pressed, start a timeout for the callback, which gets interrupted if the scanbutton gets released
      if (!vars.longPressed){
        vars.longPressTimer = setTimeout( options.onScanButtonLongPress, options.scanButtonLongPressTime, this);
        vars.longPressed = true;
      }

      return;
    }

    let character;

    switch(true){
      // If it's not the first character and we encounter a terminating character, trigger scan process
      case (vars.firstCharTime && options.suffixKeyCodes.indexOf(keyCode)!==-1):
        e.preventDefault();
        e.stopImmediatePropagation();
        scanFinished=true;
        break;

      // If it's the first character and we encountered one of the starting characters, don't process the scan
      case (!vars.firstCharTime && options.prefixKeyCodes.indexOf(keyCode)!==-1):
        e.preventDefault();
        e.stopImmediatePropagation();
        scanFinished=false;
        break;

      // Otherwise, just add the character to the scan string we're building
      default:
        character = options.keyCodeMapper.call(this, e);
        if (character === null){
          return;
        }

        if (vars.accumulatedString && keyCode == 32) {
          // prevent default behaviour for space between DMC
          e.preventDefault();
          e.stopImmediatePropagation();
        }

        vars.accumulatedString += character;

        if (options.preventDefault) {
          e.preventDefault();
        }
        if (options.stopPropagation) {
          e.stopImmediatePropagation();
        }

        scanFinished=false;
        break;
    }

    if(!vars.firstCharTime){
      vars.firstCharTime=Date.now();
    }

    vars.lastCharTime=Date.now();

    if(vars.testTimer){
      clearTimeout(vars.testTimer);
    }

    if(scanFinished){
      this.validateScanCode(vars.accumulatedString);
      vars.testTimer=false;
    } else {
      vars.testTimer=setTimeout((code) => this.validateScanCode(code), options.timeBeforeScanTest, vars.accumulatedString);
    }

    options.onKeyProcess.call(this, character, e);
    return;
  }

  private getNormalizedKeyNum(e: KeyboardEvent): number {
    return e.which || e.keyCode;
  }
}
