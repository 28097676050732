import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DrawingNumberQuery} from "@app/shared/dto/drawing-number/drawing-number.query";
import {Observable} from "rxjs";
import {DrawingNumberDto} from "@app/shared/dto/drawing-number/drawing-number.dto";
import {DrawingNumberUpdateDto} from "@app/shared/dto/drawing-number/drawing-number.update.dto";
import {CreateDrawingNumberDto} from "@app/shared/dto/drawing-number/drawing-number.create.dto";

@Injectable()
export class DrawingNumberService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/drawing-numbers';
  }

  findAll(drawingNumberQuery: DrawingNumberQuery): Observable<DrawingNumberDto[]> {
    return this.http.post<DrawingNumberDto[]>(`${this.baseUrl}`, drawingNumberQuery);
  }

  read(id: string): Observable<DrawingNumberDto> {
    return this.http.get<DrawingNumberDto>(`${this.baseUrl}/${id}`);
  }

  update(id: string, update: DrawingNumberUpdateDto, fields: string[] = []): Observable<void> {
    const params = {};
    if (fields.length > 0) {
      params['fields'] = fields;
    }
    return this.http.post<void>(`${this.baseUrl}/${id}`, update, {params});
  }

  create(createDrawingNumber: CreateDrawingNumberDto): Observable<DrawingNumberDto> {
    return this.http.put<DrawingNumberDto>(`${this.baseUrl}`, createDrawingNumber);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

}
