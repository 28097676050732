
<dvs-box header="Tool Data Export" header-color="grey">
  <p class="text-margin ">
    {{'PAGE.TOOL_PROJECT.API_MANAGE_TEXT' | translate}}
    <a class="underline" [routerLink]="['/access-keys']">{{'PAGE.TOOL_PROJECT.API_MANAGE_BUTTON' | translate}}</a>
  </p>
  <p class="text-margin">
    {{'PAGE.TOOL_PROJECT.TOOL_DATA_HEADER' | translate}}
  </p>
  <p class="text-margin">
    <span class="curl-command" test-id="idCurlToolData">
      curl -X GET "{{commonPath}}/{{toolProjectId}}/tools"  -H "time-zone: Europe/Berlin"  -H "X-DVS-ACCESS-KEY-ID: XXXX" -H "X-DVS-ACCESS-KEY-SECRET: XXXX" -H "Accept: text/csv"
    </span>
  </p>
  <p class="text-margin">
    {{'PAGE.TOOL_PROJECT.CYCLE_DATA_HEADER' | translate}}
  </p>
  <p class="text-margin">
    <span class="curl-command" test-id="idCurlCycleData">
      curl -X GET "{{commonPath}}/{{toolProjectId}}/cycles"  -H "time-zone: Europe/Berlin"  -H "X-DVS-ACCESS-KEY-ID: XXXX" -H "X-DVS-ACCESS-KEY-SECRET: XXXX" -H "Accept: text/csv"
    </span>
  </p>
  <p class="text-margin">
    {{'PAGE.TOOL_PROJECT.MOUNT_DATA_HEADER' | translate}}
  </p>
  <p class="text-margin">
    <span class="curl-command" test-id="idCurlMountData">
      curl -X GET "{{commonPath}}/{{toolProjectId}}/mounts"  -H "time-zone: Europe/Berlin"  -H "X-DVS-ACCESS-KEY-ID: XXXX" -H "X-DVS-ACCESS-KEY-SECRET: XXXX" -H "Accept: text/csv"
    </span>
  </p>
</dvs-box>
