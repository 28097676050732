import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineCaseQueryDto} from "@app/shared/dto/machine-case/machine-case.query.dto";
import {MachineCaseDto} from "@app/shared/dto/machine-case/machine-case.dto";
import {Observable} from "rxjs";
import {MachineCaseCreateDto} from "@app/shared/dto/machine-case/machine-case.create.dto";
import {MachineCaseFileDto} from "@app/shared/dto/machine-case/machine-case-file.dto";

@Injectable()
export class MachineCaseService {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = '/api/machine-cases';
  }

  findAll(machineCaseQuery: MachineCaseQueryDto): Observable<MachineCaseDto[]> {
    return this.http.post<MachineCaseDto[]>(this.baseUrl, machineCaseQuery);
  }

  countAll(machineCaseQuery: MachineCaseQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, machineCaseQuery);
  }

  createMachineCase(machineCaseCreateDto: MachineCaseCreateDto): Observable<MachineCaseDto> {
    return this.http.put<MachineCaseDto>(this.baseUrl, machineCaseCreateDto);
  }

  readMachineCase(caseId: string): Observable<MachineCaseDto> {
    return this.http.get<MachineCaseDto>(`${this.baseUrl}/${caseId}`);
  }

  listMachineCaseFiles(caseId: string): Observable<MachineCaseFileDto[]> {
    return this.http.get<MachineCaseFileDto[]>(`${this.baseUrl}/${caseId}/files`);
  }
}
