import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CustomerService} from "@app/shared/services/customer/customer.service";
import {CustomerDto} from "@app/shared/dto/customer/customer.dto";
import {CustomerQueryDto} from "@app/shared/dto/customer/customer.query.dto";
import {UserService} from "@app/shared/services/user/user.service";
import {take} from "rxjs/operators";
import {UserInfoDto} from "@app/shared/dto/user/user-info.dto";

@Component({
  selector: 'dvs-user-settings',
  templateUrl: './userSettings.component.html'
})
export class UserSettingsComponent implements OnInit {
  @Input() initials: string;
  @Input() userInfo: UserInfoDto;
  @Output() closeEvent = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();

  username: string;

  // should contain comma separated customer names
  customers = []

  activeCustomer: CustomerDto

  constructor(private customerService: CustomerService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.username = `${this.userInfo.given_name} ${this.userInfo.family_name}`
    const customerQuery = new CustomerQueryDto();
    this.customerService.findAll(customerQuery).subscribe((customers: CustomerDto[]) => {
      this.customers = customers.filter((customer: CustomerDto) => {
        // tslint:disable-next-line:triple-equals
        if (this.userInfo.customer_ids.find((id: string) => customer.id == id)){
          // tslint:disable-next-line:triple-equals
          if (this.userInfo.active_customer_id == customer.id){
            this.activeCustomer = customer;
          }
          return customer
        }
      })
    });
  }

  /**
   * specific function, returns a comma separated string of customers
   * which were found corresponding to ids
   * @param idArr array of id's (as number)
   * @param objArr array of objects like {id: string, name: string}
   * @example
   *  idArr = [1,3],
   *  objArr = [{id: "1", name: "A"}, {id: "2", name: "B"}, {id: "3", name: "C"}]
   *  returns "A, C"
   * Test:
   *  console.log(this.getCustomerNames(["1", "3", "5"], [{id: "1", name: "A"}, {id: "2", name: "B"}, {id: "3", name: "C"} ]));
   */
  private getCustomerNames(idArr: string[], objArr: { id: string, name: string }[]): string {
    let names = "";
    const customersArr: string[] = [];

    idArr.forEach((id: string) => {
      const found: CustomerDto = objArr.find(obj => obj.id === id);
      if (found) customersArr.push(found.name);
    });
    for (let i = 0; i < customersArr.length; i++) {
      names += customersArr[i];
      if (i < (customersArr.length - 1)) names += ', ';
    }
    return names;
  }

  activateCustomer(customer: CustomerDto, reload: boolean) {
    this.userService.setActiveCustomer(customer.id).pipe(
        take(1)
    ).subscribe(() => {
      this.activeCustomer = customer;
      if(reload) {
        window.location.reload()
      }
    })
  }

}
