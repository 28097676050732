import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FileMetadata} from "@app/shared/interfaces/file-upload/file-metadata";
import {FileService} from "@app/shared/services/file.service";
import {forkJoin, from, Observable, Subscription} from "rxjs";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";
import {FileUploadConfig} from "@app/shared/interfaces/file-upload/file-upload-config";

/**
 * this component uploads the file (on AWS S3 /temp) and if successful returns the result: FileMetadata into
 * the function provided from the component which is using this
 */
@Component({
  selector: 'dvs-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  @Input() uploadFile: EventEmitter<void>;
  @Input() onlyRead = false;
  @Input() maxFileSizeLimitKb = 5 * 1024;  // 5 MByte maximum
  @Output() fileUploaded: EventEmitter<FileMetadata> = new EventEmitter<FileMetadata>();
  @Output() fileRead: EventEmitter<ArrayBuffer> = new EventEmitter<ArrayBuffer>();
  @ViewChild('fileUploadInputField') uploadInputField: ElementRef;

  showProgressBar = false;
  percentDone = 0;
  fileName: string;
  inputSub: Subscription;
  fileUploadSub: Subscription;

  constructor(private fileService: FileService) { }

  ngOnInit(): void {
    if (!this.uploadFile) return;

    this.inputSub = this.uploadFile.subscribe(() => {
      this.uploadInputField.nativeElement.click();
    });
  }

  ngOnDestroy(): void {
    if (this.inputSub) this.inputSub.unsubscribe();
    if (this.fileUploadSub) this.fileUploadSub.unsubscribe();
  }

  onFileSelected($event): void {
    const inputElem: HTMLInputElement = $event.target;

    if (!inputElem || inputElem.files.length === 0) return;

    const fileToUpload: File = inputElem.files[0];
    this.fileName = fileToUpload.name;
    const fileExtension = this.fileName.split(".").pop();

    this.showProgressBar = true;

    const uploadConfig: FileUploadConfig = {
      onProgress: (progressEvent => {
        this.percentDone = Math.floor(progressEvent.loaded / progressEvent.total * 100);
      })
    };

    const requests = [];
    requests.push(this.readFromFileIntoArrayBuffer(fileToUpload));
    if (!this.onlyRead) {
      requests.push(this.fileService.createPresignedFileUploadRequest(fileExtension));
    }


    this.fileUploadSub = forkJoin(requests).subscribe(
      (allResults ) => {
        const fileContent: ArrayBuffer = allResults[0] as ArrayBuffer;

        if (this.isFileLimitExceeds(fileContent, this.maxFileSizeLimitKb)) {
          this.handleErrorDuringFileUpload(new Error(`File size exceeds a size limit of ${this.maxFileSizeLimitKb} kB`), true);
          this.hideProgressBarAndReset();
          return;
        }

        if (this.fileRead) {
          this.fileRead.emit(fileContent);
        }

        if (!this.onlyRead) {
          const presignedFileRequest: PresignedFileRequestDto = allResults[1] as PresignedFileRequestDto;
          this.fileService.uploadFile(fileContent, presignedFileRequest, uploadConfig)
          .then(() => {
            const result: FileMetadata = {
              fileName: this.fileName,
              filePath: presignedFileRequest.filePath
            };
            this.fileUploaded.emit(result);
          })
          .catch((err: any) => {
            this.handleErrorDuringFileUpload(err, true);
          })
          .finally(() => {
            this.hideProgressBarAndReset();
          });
        } else {
          const result: FileMetadata = {
            fileName: this.fileName,
          };
          this.fileUploaded.emit(result);
          this.hideProgressBarAndReset();
        }
      },
      (err) =>  {
        this.handleErrorDuringFileUpload(err, true);
      }
    );
  }

  readFromFileIntoArrayBuffer(file: File): Observable<ArrayBuffer> {
    const promise = new Promise<ArrayBuffer>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as ArrayBuffer));
      reader.readAsArrayBuffer(file);
    });

    return from(promise);
  }

  private hideProgressBarAndReset(): void {
    this.showProgressBar = false;
    this.percentDone = 0;
  }

  isFileLimitExceeds(fileContent: ArrayBuffer, maxFileSizeLimitKb: number): boolean {
    return (maxFileSizeLimitKb && fileContent.byteLength / 1024) > maxFileSizeLimitKb;
  }

  handleErrorDuringFileUpload(err: any, isAlert?: boolean): void {
    console.error("Error occurred during uploading of the file. Error: ", err);
    let errMessage: string = err;
    if (err.error) {
      errMessage = err.message;
    }
    if (isAlert) {
      alert("File was not uploaded. Error:\n" + errMessage);
    }
    this.showProgressBar = false;
  }

}
