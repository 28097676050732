import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ToolKpiPerformanceDto} from "@app/shared/dto/tool-kpi/tool-kpi-performance.dto";
import {ToolKpiMachineUsagesDto} from "@app/shared/dto/tool-kpi/tool-kpi-machine-usages.dto";
import {ToolKpiUsageHistogramDto} from "@app/shared/dto/tool-kpi/tool-kpi-usage-histogram.dto";
import {ToolKpiStabilityDto} from "@app/shared/dto/tool-kpi/tool-kpi-stability.dto";
import {
  ToolKpiUsagesLatestCyclesDto
} from "@app/shared/dto/tool-kpi/tool-kpi-usages-latest-cycles.dto";
import {
  ToolProjectDefaultDto
} from "@app/shared/dto/tool-project/tool-project-default.dto";
import {ToolProjectCostsDto} from "@app/shared/dto/tool-project/tool-project-costs.dto";
import {ToolKpiOrderScheduleDto} from "@app/shared/dto/tool-kpi/tool-kpi-order-schedule.dto";
import {ToolKpiUnmountReason} from "@app/shared/dto/tool-kpi/tool-kpi-unmountreason";
import {
  WeeklyTargetSimulationRequestDto
} from "@app/shared/dto/tool-kpi/weekly-target-simulation-request.dto";
import {WeeklyTargetSimulationDto} from "@app/shared/dto/tool-kpi/weekly-target-simulation.dto";
import {ToolKpiToolsByCycleDto} from "@app/shared/dto/tool-kpi/tool-kpi-tools-by-cycle.dto";

@Injectable()
export class ToolsKpiService {
  private readonly baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tools-kpi';
  }

  getToolProjectDefaultValues(toolProjectId: string): Observable<ToolProjectDefaultDto> {
    const url = `${this.baseUrl}/defaults/${toolProjectId}`;
    return this.http.get<ToolProjectDefaultDto>(url);
  }

  getCosts(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolProjectCostsDto> {
    const url = `${this.baseUrl}/cost/${toolProjectId}?observationDate=${observationDate}&observationDays=${observationDays}`;
    return this.http.get<ToolProjectCostsDto>(url);
  }

  getPerformance(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiPerformanceDto>  {
    const url = `${this.baseUrl}/performance/${toolProjectId}?observationDate=${observationDate}&observationDays=${observationDays}`;
    return this.http.get<ToolKpiPerformanceDto>(url);
  }

  getUnmountReasons(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiUnmountReason[]> {
    const url = `${this.baseUrl}/performance/${toolProjectId}/unmount-reasons?observationDate=${observationDate}&observationsDays=${observationDays}`;
    return this.http.get<ToolKpiUnmountReason[]>(url);
  }

  getToolsByCycle(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiToolsByCycleDto> {
    const url = `${this.baseUrl}/performance/${toolProjectId}/tools-by-cycle?observationDate=${observationDate}&observationsDays=${observationDays}`;
    return this.http.get<ToolKpiToolsByCycleDto>(url);
  }

  getMachineUsages(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiMachineUsagesDto> {
    const url = `${this.baseUrl}/performance/${toolProjectId}/machine-usages?observationDate=${observationDate}&observationsDays=${observationDays}`;
    return this.http.get<ToolKpiMachineUsagesDto>(url);
  }

  getUsageHistogram(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiUsageHistogramDto[]> {
    const url = `${this.baseUrl}/performance/${toolProjectId}/usage-histogram?observationDate=${observationDate}&observationDays=${observationDays}`;
    return this.http.get<ToolKpiUsageHistogramDto[]>(url);
  }

  getStability(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiStabilityDto> {
    const url = `${this.baseUrl}/stability/${toolProjectId}?observationDate=${observationDate}&observationDays=${observationDays}`;
    return this.http.get<ToolKpiStabilityDto>(url);
  }

  getUsagesLatestCycles(toolProjectId: string, observationDate: string, observationDays: number): Observable<ToolKpiUsagesLatestCyclesDto> {
    const url = `${this.baseUrl}/performance/${toolProjectId}/usages-latest-cycles?observationDate=${observationDate}&observationDays=${observationDays}`;
    return this.http.get<ToolKpiUsagesLatestCyclesDto>(url);
  }

  getOrderSchedule(toolProjectId: string): Observable<ToolKpiOrderScheduleDto>  {
    const url = `${this.baseUrl}/demand/simulation/${toolProjectId}/tmgt-order-schedule`;
    return this.http.get<ToolKpiOrderScheduleDto>(url);
  }

  getDemandPredictionDescription(toolProjectId: string): Observable<string> {
    const url = `${this.baseUrl}/demand/description/${toolProjectId}`;
    return this.http.get(url, {responseType: 'text'});
  }

  getWeeklyTargetSimulation(toolProjectId: string, request: WeeklyTargetSimulationRequestDto): Observable<WeeklyTargetSimulationDto> {
    const url = `${this.baseUrl}/demand/simulation/${toolProjectId}/weekly-target-simulation`;
    return this.http.post<WeeklyTargetSimulationDto>(url, request);
  }

  getTotalUsagesPrediction(toolProjectId: string, toolId: string): Observable<Map<string, number>> {
    const url = `${this.baseUrl}/prediction/${toolProjectId}/total-usages-prediction?toolId=${toolId}`;
    return this.http.get<Map<string, number>>(url);
  }

  getCycleUsagesPrediction(toolProjectId: string, toolId: string): Observable<Map<string, number>> {
    const url = `${this.baseUrl}/prediction/${toolProjectId}/cycle-usages-prediction?toolId=${toolId}`;
    return this.http.get<Map<string, number>>(url);
  }
}
