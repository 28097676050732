import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ErrorInterceptor} from "@app/shared/interceptor/error.interceptor";
import {LocalDataPortalInterceptor} from "@app/shared/interceptor/local.data-portal.interceptor";
import {CookieInterceptor} from "@app/shared/interceptor/cookie.interceptor";
import {CustomerInterceptor} from "@app/shared/interceptor/customer.interceptor";

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CustomerInterceptor , multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor , multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor , multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocalDataPortalInterceptor , multi: true },
];
