import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CaseTimeTrackingQueryDto} from "@app/shared/dto/case-time-tracking/case-time-tracking.query.dto";
import {CaseTimeTrackingDto} from "@app/shared/dto/case-time-tracking/case-time-tracking.dto";
import {Observable} from "rxjs";

@Injectable()
export class CaseTimeTrackingService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/case-time-trackings';
  }

  findAll(query: CaseTimeTrackingQueryDto): Observable<CaseTimeTrackingDto[]> {
    return this.http.post<CaseTimeTrackingDto[]>(this.baseUrl, query);
  }

  countAll(query: CaseTimeTrackingQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }
}
