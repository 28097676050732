import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CustomerQueryDto} from "@app/shared/dto/customer/customer.query.dto";
import {CustomerDto} from "@app/shared/dto/customer/customer.dto";
import {Observable} from "rxjs";

@Injectable()
export class CustomerService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/customers';
  }

  findAll(query: CustomerQueryDto): Observable<CustomerDto[]> {
    return this.http.post<CustomerDto[]>(this.baseUrl, query);
  }

}
