import {Component, ViewChild} from "@angular/core";
import {BarcodeFormat} from "@zxing/library";
import {MatDialogRef} from "@angular/material/dialog";
import {DmcScannerComponent} from "@app/shared/components/dmc-scanner/dmc-scanner.component";

@Component({
  selector: 'dvs-scanner',
  templateUrl: './scanner.component.html',
  // styleUrls: ['./scanner.component.css']
})
export class ScannerComponent {
  formats: BarcodeFormat[] = [ BarcodeFormat.DATA_MATRIX ];
  @ViewChild('scanner', { static: false })
  scanner: DmcScannerComponent;

  constructor(private dialogRef: MatDialogRef<ScannerComponent>) {
  }

  scanSuccess($event: string) {
    this.dialogRef.close($event)
  }

}
