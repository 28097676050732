<div class="main" style="color: var(--main-text-color); background-color: var(--page-background); min-height: 100%">
  <dvs-header></dvs-header>

  <div [class]="'content' + (centered ? ' centered' : '')">
    <div style="position: fixed; width: 100%; top: 0; z-index: 2000">
      <div class="container">
        <section class="section no-space-before">
          <toast></toast>
        </section>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <dvs-footer></dvs-footer>
</div>

