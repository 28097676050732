import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EdgeDeviceDeploymentQueryDto} from "@app/shared/dto/edge-device-deployment/edge-device-deployment.query.dto";
import {EdgeDeviceDeploymentDto} from "@app/shared/dto/edge-device-deployment/edge-device-deployment.dto";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";

@Injectable()
export class EdgeDeviceDeploymentService {

  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/edge-device-deployments';
  }

  findAll(query: EdgeDeviceDeploymentQueryDto): Observable<EdgeDeviceDeploymentDto[]> {
    return this.http.post<EdgeDeviceDeploymentDto[]>(this.baseUrl, query);
  }

  countAll(query: EdgeDeviceDeploymentQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

  createPresignedFileDownloadRequest(edgeDeviceDeploymentId: string): Observable<PresignedFileRequestDto> {
    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/${edgeDeviceDeploymentId}/software-update`);
  }

}
