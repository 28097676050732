import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'dvs-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() size: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
