export enum Resource {
  ALL_DATA = 0,
  APPLICATION = 1,
  CUSTOMER = 2,
  CUSTOMER_APPLICATION = 3,
  MACHINE = 4,
  MACHINE_EDGE_DEVICE = 5,
  MACHINE_ATTRIBUTE = 6,
  MACHINE_FILE = 7,
  MACHINE_LOCATION = 8,
  MACHINE_NOTICE = 9,
  PRODUCT = 10,
  TOOL_PROJECT = 11,
  SUPPLIER = 12,
  TOOL = 13,
  TOOL_ACTION = 14,
  TOOL_ACTION_PARAM = 15,
  TOOL_ACTION_SCOPE = 16,
  TOOL_ACTION_TYPE = 17,
  TOOL_MOUNT = 18,
  TOOL_STATUS = 19,
  TOOL_UNMOUNT_REASON = 20,
  TOOL_USAGE_CYCLE = 21,
  TOOL_USAGE_DATA = 22,
  FILE = 23,
  ACCESS_KEY = 24,
  ACCESS_REQUEST = 25,
  USER_INFO = 26,
  USER = 27,
  USER_OWN = 28,
  ROLE = 29,
  USER_ROLE = 30,
  USER_ROLE_OWN = 31,
  USER_CUSTOMER = 32,
  USER_CUSTOMER_OWN = 33,
  TOOL_EVENT = 34,
  ROLE_PERMISSION = 35,
  ACCESS_KEY_OWN = 36,
  ROLE_TEMPLATE = 37,
  ROLE_TEMPLATE_PERMISSION = 38,
  RESOURCE = 39,
  RESOURCE_GROUP = 40,
  GLOBAL_SETTINGS = 41,
  TOOL_MEASUREMENT_DATA = 42,
  USER_APPLICATION= 43,
  INVITATION  = 44,
  EMAIL= 45,
  SIMULATION_SCENARIO = 46,
  MACHINE_DOCUMENTATION = 47,
  MACHINE_EDGE_PROVISIONING_CLAIM = 48,
  MACHINE_EDGE_PROVISIONING_TEMPLATE = 49,
  MACHINE_COMPONENT = 50,
  SOFTWARE_DOWNLOAD = 51,
  DRAWING_NUMBER = 52,
  EDGE_DEPLOYMENT = 53,
  EDGE_DEPLOYMENT_COMPONENT = 54,
  EDGE_DEVICE_DEPLOYMENT = 55,
  EDGE_DEVICE_VERSION = 56,
  TOOL_ORDER = 57,
  MANUFACTURER = 58,
  MACHINE_MAPPING = 59,
  SHAREPOINT_MACHINE_DOC_SYNC = 60,
  ROLE_SET = 61,
  ROLE_SET_TEMPLATE = 62,
  CUSTOMER_ROLE_SET = 63,
  USER_ROLE_SET_ROLE_TEMPLATE = 64,
  ROLE_SET_ACCOUNT_TYPE_MAPPING = 65,
  ROLE_SET_APPLICATION = 66,
  MACHINE_CASE = 67
}
