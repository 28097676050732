import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";
import { Observable} from "rxjs";
import axios, {AxiosRequestConfig} from "axios";
import {FileUploadConfig} from "@app/shared/interfaces/file-upload/file-upload-config";

@Injectable()
export class FileService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/files';
  }

  createPresignedFileUploadRequest(fileExtension: string): Observable<PresignedFileRequestDto> {
    const params = new HttpParams().set('file-extension', fileExtension);

    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/presigned-upload-request`, { params });
  }

  createPresignedFileDownloadRequest(path: string): Observable<PresignedFileRequestDto> {
    const params = new HttpParams().set('path', path);

    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/presigned-download-request`, { params });
  }

  uploadFile(data: string | ArrayBuffer, request: PresignedFileRequestDto, uploadConfig?: FileUploadConfig): Promise<void> {
    const axiosHeaders: any = {};
    for (const key in request.headers) {
      if (key !== 'host' && request.headers[key]) {
        request.headers[key].forEach(value => axiosHeaders[key] = value)
      }
    }

    const config: AxiosRequestConfig = {
      onUploadProgress: uploadConfig.onProgress,
      method: 'put',
      headers: axiosHeaders
    };
    return new Promise<void>((resolve, reject) => {
      axios.put(request.url, data, config)
      .then(() => resolve())
      .catch(() => reject());
    });
  }


}

