import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from './environments/environment';

import {AppModule} from "@app/app.module";

if (environment.production) {
  enableProdMode();
}

const open = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function(method, url, ...rest) {
  if (url.startsWith('./assets/bwipjs-fonts')) {
    url = url.replace('./', '/public/')
  }
  return open.call(this, method, url, ...rest);
};

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
