import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfigService} from "@app/shared/services/config.service";
import {HttpInterceptorProviders} from "@app/interceptor-provider";
import {AppRoutingModule} from "@app/app-routing.module";
import {AppComponent} from '@app/app.component';
import {SharedModule} from "@app/shared/shared.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {defineCustomElements} from "@dvs-design-system/components-js/loader";
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import * as de from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";
import {PreviousRouteService} from "@app/shared/services/previous-route.service";

defineCustomElements(window, {
  resourcesUrl: "/public/"
}).then();

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, "/public/assets/i18n/");
}


export function oAuthStorageFactory(): OAuthStorage { return localStorage; }

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InfiniteScrollModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/api', '/data-portal', '/datagram-service/api', '/tools-kpi-api/api', '/proac/api'],
        sendAccessToken: true
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    // { provide: OAuthStorage, useFactory: oAuthStorageFactory },
    { provide: APP_INITIALIZER, useFactory: (configService: ConfigService) => () => configService.init(), deps: [ConfigService], multi: true },
    HttpInterceptorProviders,
    PreviousRouteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default);
  }
}

