import {Injectable} from "@angular/core";
import moment from "moment";
import {
  ChartDataDefaults,
  ProductionChartParameters,
  ChartDataPeriodFormat
} from "@app/shared/model/production/production-summary-chart-parameters";
import {DataGramAggregationDataDto, DataResult} from "@app/shared/dto/production/datagram-aggregation-data.dto.dto";
import {ChartDataElement, ChartDataSeries} from "@app/shared/model/nvd3-chart/chart-data";

@Injectable()
/**
 * mapping from/to internally used objects and objects got by remote service
 */
export class ProductionDataMapperService {

  /**
   * returns date in right format depends on parameters
   * @param date date string coming from remote service (like '2022-06-18T02:29:44.769000')
   * @param params summary chart parameters
   */
  private static getDateForSummaryChart(date: string, params: ProductionChartParameters): string {
    const chartParameters: ProductionChartParameters = {
      ...new ChartDataDefaults(),
      ...params
    };

    if (!params.showDateAsPeriod) {
      return moment(date).format(chartParameters.dateFormat);
    }

    switch (params.periodFormat) {
      case ChartDataPeriodFormat.WEEK_NUMBER: {
        return moment(date).week().toString();
      }
      case ChartDataPeriodFormat.MONTH_NUMBER: {
        return moment(date).month().toString();
      }
      case ChartDataPeriodFormat.MONTH_NAME: {
        return moment(date).format("MMM");
      }
      case ChartDataPeriodFormat.MONTH_NAME_PLUS_YEAR: {
        const month: string = moment(date).format("MMM");
        const year: string = moment(date).format("YY");
        return `${month} ${year}`;
      }
      default: {
        console.warn("ProductionDataMapperService, wrong date format:", params.periodFormat);
        return null;
      }
    }
  }

  private static getDateForTable(date: string): string {
    return moment(date).format("DD-MM-YY hh:mm:ss")
  }

  getRowsCountFromDto(tableCountDto: DataGramAggregationDataDto): number {
    return tableCountDto?.groups[0]?.results[0]?.count;
  }

  toChartDataFromDto(chartDto: DataGramAggregationDataDto, params: ProductionChartParameters): ChartDataSeries[] {
    if (!chartDto || !chartDto.groups) {
      console.warn("ProductionDataMapperService.toChartDataFromDto(): an error occurred by calling of a remote service!");
      return null;
    }
    if (chartDto.groups.length === 0) {
      console.warn("ProductionDataMapperService.toChartDataFromDto(): got no data from a remote service!");
      return [];
    }

    const chartDataElements: ChartDataElement[] = [];
    chartDto.groups[0]?.results.forEach((elem: DataResult) => {
      chartDataElements.push({
          label: ProductionDataMapperService.getDateForSummaryChart(elem['start_timestamp'], params),
          value: elem['count']
        }
      );
    });
    return [{ values: chartDataElements}];
  }

}
