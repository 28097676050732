<!--
 'buffer' -> dots running
 'determinate'" -> dots
-->

<div class="upload-container" *ngIf="showProgressBar">
  <div class="progress_bar_file_name">{{fileName}}</div>

  <mat-progress-bar class="progress_bar" mode="buffer" [value]="percentDone"></mat-progress-bar>

  <div class="progress_bar_progress_status">{{percentDone}} %</div>

</div>
<input type="file" style="display: none;" (change)="onFileSelected($event)" #fileUploadInputField>
