export abstract class BaseQueryDto {
  sortOrder: string;
  pageNumber = 0;
  pageSize = 50;
  queryType: string;

  isEmpty(): boolean{
    return Object.keys(this).length === 3;
  }
}
