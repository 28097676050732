import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {from, Observable, of} from "rxjs";
import {AuthService} from "@app/shared/services/auth.service";
import {UserService} from "@app/shared/services/user/user.service";
import {CustomerUserService} from "@app/shared/services/user/customer-user.service";
import {CustomerUserQueryDto} from "@app/shared/dto/user/customer-user.query.dto";
import {catchError, map, switchMap, tap} from "rxjs/operators";

@Injectable()
export class TenantGuard implements CanActivate {

  constructor(private readonly authService: AuthService,
              private readonly userService: UserService,
              private readonly customerUserService: CustomerUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const tenantId: string = route.queryParams['tenant'];

    if (!tenantId) return of(true);

    if (!this.authService.isLoggedIn()) {
      this.authService.logIn(state.url);
      return of(false);
    }

    return from(this.authService.getUserInfoPromise()).pipe(
      switchMap(userInfo => {
        if (userInfo.active_customer_id == tenantId) {
          return of(true);
        }

        // Check if user has access to requested tenant
        const customerUserQuery = new CustomerUserQueryDto();
        customerUserQuery.userId = userInfo.id;

        return this.customerUserService.findAll(customerUserQuery).pipe(
          switchMap(customerUsers => {
            const canAccessTenant = !!customerUsers.find(customerUser => customerUser.customer.id == tenantId);
            if (!canAccessTenant) return of(true);

            // Switch active customer and reload the page
            return this.userService.setActiveCustomer(tenantId).pipe(
              tap(() => {
                window.location.reload();
              }),
              map(() => true),
              catchError(() => of(true))
            );
          })
        )
      })
    )
  }

}
