import {Injectable} from "@angular/core";
import {Router, RoutesRecognized} from "@angular/router";
import {pairwise, filter} from "rxjs/operators";

@Injectable()
export class PreviousRouteService {

  private previousUrl: string;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }
}
