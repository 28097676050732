import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MachineCustomerDto} from "@app/shared/dto/machine/machineCustomerDto";
import {MachineDetailsDto} from "@app/shared/dto/machine/machineDetailsDto";
import {MachineDetailsCustomAttributesView} from "@app/shared/dto/machine/machineDetailsCustomAttributesView";
import {MachineFileDto} from "@app/shared/dto/machine/machineFileDto";
import {Observable} from "rxjs";
import {MachineNoticeDto} from "@app/shared/dto/machine/machineNoticeDto";
import {UpdateMachineDto} from "@app/shared/dto/machine/update-machine.dto";
import {CreateMachineDto} from "@app/shared/dto/machine/create-machine.dto";
import {MachineQueryDto} from "@app/shared/dto/machine/machine.query.dto";

@Injectable()
export class MachineService {
  private baseUrl: string;

  machines = []

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/machines';
  }

  findMachinesForUserAssignedCustomers(query: MachineQueryDto): Observable<MachineCustomerDto[]> {
    return this.http.post<MachineCustomerDto[]>(this.baseUrl, query);
  }

  findDetailsForUserMachine(machineId: string): Observable<MachineDetailsDto>  {
    return this.http.get<MachineDetailsDto>(`${this.baseUrl}/${machineId}`);
  }


  removeUserDefinedAttributeForMachine(attributeId: string): Observable<void> {
    return this.http.get<void>(`${this.baseUrl}/removeAttribute/${attributeId}`);
  }

  addUserDefinedAttributeForMachine(attributeValueDto: MachineDetailsCustomAttributesView): Observable<void>  {
    return this.http.post<void>(`${this.baseUrl}/addAttribute`, attributeValueDto);
  }

  addFileToMachine(fileDto: MachineFileDto): Observable<void>  {
    return this.http.post<void>(`${this.baseUrl}/addFile`, fileDto);
  }

  loadFilesToMachine(machineId: string): Observable<MachineFileDto[]>  {
    return this.http.get<MachineFileDto[]>(`${this.baseUrl}/loadFilesForMachine/${machineId}`);
  }


  deleteFileFromMachine(fileDto: MachineFileDto): Observable<void>  {
    return this.http.post<void>(`${this.baseUrl}/deleteFile`, fileDto);
  }

  findNotice(machineId): Observable<MachineNoticeDto> {
    return this.http.get<MachineNoticeDto>(`${this.baseUrl}/findNotice/${machineId}`);
  }

  addNotice(noticeDto: MachineNoticeDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/addNotice`, noticeDto);
  }

  createMachine(createMachineDto: CreateMachineDto): Observable<MachineDetailsDto> {
    return this.http.put<MachineDetailsDto>(`${this.baseUrl}`, createMachineDto);
  }

  update(machineId: string, updateMachineDto: UpdateMachineDto, fields: string[] = []): Observable<void> {
    const params = {};
    if (fields.length > 0) {
      params['fields'] = fields;
    }
    return this.http.post<void>(`${this.baseUrl}/${machineId}`, updateMachineDto, {
      params
    })
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
