import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FileMetadata} from "@app/shared/interfaces/file-upload/file-metadata";
import {FileService} from "@app/shared/services/file.service";
import {forkJoin, from, Observable, Subscription} from "rxjs";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";
import {FileUploadConfig} from "@app/shared/interfaces/file-upload/file-upload-config";
import {ToastService} from "@app/shared/services/toast/toast.service";

/**
 * this component uploads the files (on AWS S3 /temp) and if successful returns the result: FileMetadata into
 * the function provided from the component which is using this
 */
@Component({
  selector: 'dvs-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  @Input() uploadFile: EventEmitter<void>;
  @Input() visible: boolean = false;
  @Input() drop: boolean = false;
  @Input() showProgress: boolean = true;
  @Input() multiple: boolean = false;
  @Input() placeholder: string;
  @Input() disabled: boolean = false;
  @Input() accept: string;
  @Input() onlyRead = false;
  @Input() maxFileSizeLimitKb = 5 * 1024;  // 5 MByte maximum
  @Output() fileUploaded: EventEmitter<FileMetadata | FileMetadata[]> = new EventEmitter<FileMetadata | FileMetadata[]>();
  @Output() fileRead: EventEmitter<ArrayBuffer | ArrayBuffer[]> = new EventEmitter<ArrayBuffer | ArrayBuffer[]>();
  @ViewChild('fileUploadInputField') uploadInputField: ElementRef;

  files: {name: string; percentDone: number; showProgress: boolean}[] = [];

  inputSub: Subscription;
  fileUploadSub: Subscription;

  constructor(private fileService: FileService,
              private toastService: ToastService) { }

  ngOnInit(): void {
    if (!this.uploadFile) return;

    this.inputSub = this.uploadFile.subscribe(() => {
      this.uploadInputField.nativeElement.click();
    });
  }

  ngOnDestroy(): void {
    if (this.inputSub) this.inputSub.unsubscribe();
    if (this.fileUploadSub) this.fileUploadSub.unsubscribe();
  }

  onFileSelected($event): void {
    let files: FileList;

    if ($event instanceof CustomEvent) {
      if ($event.detail && $event.detail.files) {
        if ($event.detail.files.length == 0) return;
        files = $event.detail.files;
      }
    } else {
      const inputElem: HTMLInputElement = $event.target;
      if (!inputElem || inputElem.files.length === 0) return;

      files = inputElem.files;
    }

    const filesToUpload: File[] = Array.from(files);

    for (const file of filesToUpload) {
      this.files.push({ name: file.name, percentDone: 0, showProgress: true });
      const fileExtension = file.name.split(".").pop();

      const fileIndex = this.files.length - 1;
      const uploadConfig: FileUploadConfig = {
        onProgress: (progressEvent => {
          this.files[fileIndex].percentDone = Math.floor(progressEvent.loaded / progressEvent.total * 100);
        })
      };

      const requests = [];
      requests.push(this.readFromFileIntoArrayBuffer(file));
      if (!this.onlyRead) {
        requests.push(this.fileService.createPresignedFileUploadRequest(fileExtension));
      }

      this.fileUploadSub = forkJoin(requests).subscribe(
        (allResults ) => {
          const fileContent: ArrayBuffer = allResults[0] as ArrayBuffer;

          if (this.isFileLimitExceeds(fileContent, this.maxFileSizeLimitKb)) {
            this.handleErrorDuringFileUpload(new Error(`File size exceeds a size limit of ${this.maxFileSizeLimitKb} kB`), true);
            this.files[fileIndex].showProgress = false;
            return;
          }

          if (this.fileRead) {
            this.fileRead.emit(fileContent);
          }

          if (!this.onlyRead) {
            const presignedFileRequest: PresignedFileRequestDto = allResults[1] as PresignedFileRequestDto;
            this.fileService.uploadFile(fileContent, presignedFileRequest, uploadConfig)
            .then(() => {
              const result: FileMetadata = {
                fileName: file.name,
                filePath: presignedFileRequest.filePath,
                fileContent: fileContent,
                fileSize: fileContent.byteLength
              };
              this.fileUploaded.emit(result);
            })
            .catch((err: any) => {
              this.handleErrorDuringFileUpload(err, true);
            })
            .finally(() => {
              this.files[fileIndex].showProgress = false
            });
          } else {
            const result: FileMetadata = {
              fileName: file.name,
            };
            this.fileUploaded.emit(result);
            this.files[fileIndex].showProgress = false
          }
        },
        (err) =>  {
          this.handleErrorDuringFileUpload(err, true);
        }
      );
    }
  }

  readFromFileIntoArrayBuffer(file: File): Observable<ArrayBuffer> {
    const promise = new Promise<ArrayBuffer>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as ArrayBuffer));
      reader.readAsArrayBuffer(file);
    });

    return from(promise);
  }

  isFileLimitExceeds(fileContent: ArrayBuffer, maxFileSizeLimitKb: number): boolean {
    return (maxFileSizeLimitKb && fileContent.byteLength / 1024) > maxFileSizeLimitKb;
  }

  handleErrorDuringFileUpload(err: any, isAlert?: boolean): void {
    console.error("Error occurred during uploading of the file. Error: ", err);
    let errMessage: string = err;
    if (err.error) {
      errMessage = err.message;
    }
    if (isAlert) {
      this.toastService.error("FILE_UPLOAD", "FILE_UPLOAD_ERROR", { descriptionParams: {errMessage: errMessage} });
    }
  }

}
