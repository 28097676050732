import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AuthService} from "@app/shared/services/auth.service";

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const setHeaders = {};
    if (this.authService.getUserInfo() != undefined && this.authService.getUserInfo().active_customer_id) {
      setHeaders["DVS-Customer-Id"] = "" + this.authService.getUserInfo().active_customer_id;
    }

    // send active customer in appropriate header
    req = req.clone({
      setHeaders
    });

    return next.handle(req);
  }

}
