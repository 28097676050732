import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineMappingDto} from "@app/shared/dto/machine-mapping/machine-mapping.dto";
import {Observable} from "rxjs";
import {MachineMappingQueryDto} from "@app/shared/dto/machine-mapping/machine-mapping.query.dto";

@Injectable()
export class MachineMappingService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/machine-mappings';
  }

  findAll(query: MachineMappingQueryDto): Observable<MachineMappingDto[]> {
    return this.http.post<MachineMappingDto[]>(`${this.baseUrl}`, query);
  }
}
