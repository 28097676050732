import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolProjectQueryDto} from "@app/shared/dto/tool-project/tool-project.query.dto";
import {ToolProjectDto} from "@app/shared/dto/tool-project/tool-project.dto";
import {CreateToolProjectDto} from "@app/shared/dto/tool-project/create-tool-project.dto";
import {ImportToolDataRequestDto} from "@app/shared/dto/tool-project/import-tool-data-request.dto";
import {Observable} from "rxjs";
import {ToolProjectSettingsDto} from "@app/shared/dto/tool-project/tool-project-settings.dto";
import {ForecastToolRemovalRequestDto} from "@app/shared/dto/tool/forecast-tool-removal-request.dto";
import {ToolCycleEstimationResultDto} from "@app/shared/dto/tool-kpi/tool-cycle-estimation-result.dto";
import {AttachmentDto} from "@app/shared/dto/file/attachment.dto";
import {
  ToolProjectDrawingNumbersResponseDto
} from "@app/shared/dto/tool-project/tool-project-drawing-numbers.response.dto";
import {ToolProjectExportDto} from "@app/shared/dto/tool-project/tool-project.export.dto";
import {
  CyclePredictionRequestDto
} from "@app/shared/dto/tool-kpi/cycle-prediction-request.dto";
import {
  ToolProjectImportRequestDto
} from "@app/shared/dto/tool-project/tool-project-import.request.dto";

@Injectable()
export class ToolProjectService {
  private readonly baseUrl: string;
  private readonly baseUrlKPIs: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tool-projects';
    this.baseUrlKPIs = '/tools-kpi-api/api/v1';
  }

  findAll(query: ToolProjectQueryDto): Observable<ToolProjectDto[]> {
    return this.http.post<ToolProjectDto[]>(this.baseUrl, query);
  }

  create(createToolProject: CreateToolProjectDto): Observable<ToolProjectDto> {
    return this.http.put<ToolProjectDto>(this.baseUrl, createToolProject);
  }

  read(id: string): Observable<ToolProjectDto> {
    return this.http.get<ToolProjectDto>(`${this.baseUrl}/${id}`);
  }

  drawingNumbers(toolProjectId: string): Observable<ToolProjectDrawingNumbersResponseDto> {
    return this.http.get<ToolProjectDrawingNumbersResponseDto>(`${this.baseUrl}/${toolProjectId}/drawing-numbers`);
  }

  importToolData(request: ImportToolDataRequestDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/import-tool-data`, request);
  }

  updateProjectImage(id: string, request: AttachmentDto): Observable<AttachmentDto> {
    return this.http.post<AttachmentDto>(`${this.baseUrl}/${id}/update-project-image`, request);
  }

  update(toolProjectId: string, toolProjectSettings: ToolProjectSettingsDto, fields: string[] = []): Observable<void> {
    const params = {};
    if (fields.length > 0) {
      params['fields'] = fields;
    }
    return this.http.post<void>(`${this.baseUrl}/${toolProjectId}`, toolProjectSettings, {
      params
    })
  }

  delete(toolProjectId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${toolProjectId}`);
  }

  getForecastRemovalValue(toolProjectId: string, request: ForecastToolRemovalRequestDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/${toolProjectId}/forecast-tool-removal`, request);
  }

  estimateToolCycles(toolProjectId: string, toolIds?: string[]): Observable<ToolCycleEstimationResultDto[]> {
    const request = new CyclePredictionRequestDto();
    request.statisticalFallbackMethod = "MEDIAN";
    request.toolIdFilter = toolIds;
    return this.http.post<ToolCycleEstimationResultDto[]>(`${this.baseUrl}/${toolProjectId}/estimate-tool-cycles`, request);
  }

  exportData(toolProjectId: string): Observable<ToolProjectExportDto> {
    return this.http.get<ToolProjectExportDto>(`${this.baseUrl}/${toolProjectId}/export`);
  }

  importData(toolProjectImportRequestDto: ToolProjectImportRequestDto): Observable<ToolProjectDto> {
    return this.http.post<ToolProjectDto>(`${this.baseUrl}/import`, toolProjectImportRequestDto);
  }
}
