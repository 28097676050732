import {AlertType} from "@app/shared/model/alert/alert-type";

export class Alert {
  id?: string;
  type?: AlertType;
  message?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
  fade?: boolean;
  params?: Object;

  constructor(init?:Partial<Alert>) {
    Object.assign(this, init);
  }
}