import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ConfigService} from "@app/shared/services/config.service";

@Injectable()
export class LocalDataPortalInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith("/datagram-service")) {
      return next.handle(req);
    }

    return new Observable<HttpEvent<any>>((observer) => {
      this.configService.getConfigPromise().then(() => {
        if (this.configService.getConfig().envName === "local") {
          req = req.clone({
            url: this.configService.getConfig().dataPortalHostname + req.urlWithParams
          });
        }
        next.handle(req).subscribe((res) => {
          observer.next(res);
        });
      })
    });
  }

}
