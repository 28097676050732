import {ActionScope} from "@app/shared/model/action/action-scope";
import {ActionType} from "@app/shared/model/action/action-type";
import {BaseQueryDto} from "@app/shared/dto/common/base.query.dto";

export class ActionCodeQueryDto extends BaseQueryDto {
  actionType: ActionType;
  actionScope: ActionScope;
  toolProjectId: string;

  constructor() {
    super();
    this.queryType = 'action-code';
  }
}
