import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserRoleQueryDto} from "@app/shared/dto/user-role/user-role.query.dto";
import {UserRoleDto} from "@app/shared/dto/user-role/user-role.dto";
import {CreateUserRoleDto} from "@app/shared/dto/user-role/create-user-role.dto";

@Injectable()
export class UserRoleService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/user-roles';
  }

  findAll(query: UserRoleQueryDto): Observable<UserRoleDto[]> {
    return this.http.post<UserRoleDto[]>(this.baseUrl, query);
  }

  create(query: CreateUserRoleDto): Observable<UserRoleDto> {
    return this.http.put<UserRoleDto>(`${this.baseUrl}/new`, query);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  countAll(query: UserRoleQueryDto): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/count`, query);
  }

}