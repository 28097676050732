import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileMetadataTreeItemDto} from "@app/shared/dto/file/file-metadata-tree-item.dto";
import {PresignedFileRequestDto} from "@app/shared/dto/file/presigned-file-request.dto";

@Injectable()
export class SoftwareDownloadService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = "/api/software-downloads";
  }

  findAvailableDownloads(): Observable<FileMetadataTreeItemDto[]> {
    return this.http.get<FileMetadataTreeItemDto[]>(`${this.baseUrl}`);
  }

  createPresignedFileDownloadRequest(path: string): Observable<PresignedFileRequestDto> {
    const params = new HttpParams().set('path', path.startsWith("/") ? path : "/" + path);

    return this.http.get<PresignedFileRequestDto>(`${this.baseUrl}/presigned-download-request`, { params });
  }

}
